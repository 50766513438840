<template>
  <div>
    <highcharts
      class="hc highcharts-figure"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "DebtChart",
  data(instance) {
    return {
      chartOptions: {
        chart: {
          type: "column",
          marginLeft: 150,
          marginRight: 150,
          spacingTop: 30,
          spacingBottom: 30,
        },
        title: {
          text: undefined,
        },
        xAxis: {
          type: "category",
          labels: {
            rotation: -45,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "จำนวนเงิน",
          },
          softMax: 1000,
          labels: {
            format: "{value:,.0f}",
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          pointFormat: "ยอดหนี้ : <b>{point.y:,.0f} บาท</b>",
        },
        series: [
          {
            name: "หนี้",
            data: instance.debtAmountData,
            dataLabels: {
              enabled: true,
              rotation: -90,
              color: "#FFFFFF",
              align: "right",
              format: "{point.y:.1f}", // one decimal
              y: 10, // 10 pixels down from the top
              style: {
                fontSize: "13px",
              },
            },
          },
        ],
      },
    };
  },
  props: {
    debtAmountData: Array,
  },
  watch: {
    debtAmountData: {
      handler: function (val) {
        this.$data.chartOptions.series[0].data = val;
      },
    },
  },
});
</script>
