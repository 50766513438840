import { h, resolveComponent } from "vue";
import NumberedIndex from "@/views/numbered/NumberedIndex.vue";

const numberedRoutes = [
  {
    path: "",
    name: "numberedRoutes",
    component: {
      render: () => h(resolveComponent("router-view")),
    },
    children: [
      {
        path: "",
        name: "NumberedIndex",
        component: NumberedIndex,
      },
    ],
  },
];

export default numberedRoutes;
