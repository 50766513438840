import { request } from "./api";

export function index(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}

export function show(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}
