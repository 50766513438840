<template>
  <q-dialog class="dialog" v-model="open" persistent>
    <q-card style="width: 1000px; max-width: 1000px">
      <q-card-section class="row justify-center header">
        <div class="text-h5">
          หวย สรุปเลขอั้น {{ poy !== null ? poy.desc : "" }}
          {{ round !== null ? round.desc : "" }} /
          {{ format !== null ? format.desc : "" }}
        </div>
      </q-card-section>
      <q-card-section class="row justify-end">
        <div class="col-3">
          <q-input filled v-model="findNumberText" label="ค้นหาตัวเลข" dense>
            <template v-slot:append>
              <q-icon
                @click="handleClickSearch"
                name="search"
                class="cursor-pointer"
              />
            </template>
          </q-input>
        </div>
      </q-card-section>
      <q-card-section class="row q-col-gutter-x-md q-pt-none">
        <div class="col-6">
          <span class="text-subtitle1 text-weight-medium">Step อัตราจ่าย</span>
          <q-table
            class="sticky-virtscroll-table financial-table"
            table-class="text-grey-8"
            table-header-class="text-white"
            :table-header-style="{ backgroundColor: '#929292' }"
            :rows="rows"
            :columns="columns"
            row-key="condition"
            :separator="separator"
            virtual-scroll
            v-model:pagination="pagination"
            :rows-per-page-options="[0]"
          />
        </div>
        <div class="col-6 text-body2">
          <div class="q-mb-md">
            <span>ยอดแทงรวมทั้งหมด</span>
            <span class="float-right">
              {{ totalbet === "" ? "-" : totalbet }} บาท
            </span>
          </div>
          <div class="q-mb-md">
            <span>แทงได้ต่ำสุดต่อครั้ง</span>
            <span class="float-right">
              {{ betMin === "" ? "-" : betMin }} บาท
            </span>
          </div>
          <div class="q-mb-md">
            <span>แทงได้สูงสุดต่อครั้ง</span>
            <span class="float-right">
              {{ betMax === "" ? "-" : betMax }} บาท
            </span>
          </div>
          <div class="q-mb-md">
            <span>แทงได้สุงสุดต่อคน</span>
            <span class="float-right">
              {{ maxPerNumber === "" ? "-" : maxPerNumber }} บาท
            </span>
          </div>
          <div>
            <span>อัตราจ่ายขณะนี้ บาทละ</span>
            <span class="float-right">
              {{ payPerBaht === "" ? "-" : payPerBaht }} บาท
            </span>
          </div>
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          @click="$emit('closeDialog')"
          label="ปิด"
          color="grey"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { reactive, toRefs, watch } from "vue";
import { range } from "@/services/numbered-service";

export default {
  props: ["openDialog", "poy", "round", "format"],
  setup(props) {
    const state = reactive({
      columns: [
        {
          name: "condition",
          label: "เงื่อนไข",
          field: "condition",
          sortable: true,
          align: "center",
        },
        {
          name: "rate",
          label: "อัตราจ่ายบาทละ",
          field: "rate",
          sortable: true,
          align: "center",
        },
      ],
      rows: [
        {
          condition: "มากกว่า 0",
          rate: "900",
        },
      ],
      pagination: { rowsPerPage: 10 },
      separator: "vertical",
      open: false,
      findNumberText: "",
      totalbet: "",
      betMin: "",
      betMax: "",
      maxPerNumber: "",
      payPerBaht: "",
      poy: [],
      round: [],
      format: [],
    });
    watch(
      () => [props.openDialog, props.poy, props.round, props.format],
      (
        [openDialog, poy, round, format],
        [prevOpenDialog, prevPoy, prevRound, prevFormat]
      ) => {
        console.log(prevOpenDialog);
        if (state.open !== openDialog) {
          state.open = openDialog;
          if (state.open === true) {
            state.totalbet = "";
            state.betMin = "";
            state.betMax = "";
            state.maxPerNumber = "";
            state.payPerBaht = "";
            state.rows = [];
          }
        }
        if (prevPoy === null || prevPoy.length !== 0) {
          state.poy = poy;
        }
        if (prevRound === null || prevRound.length !== 0) {
          state.round = round;
        }
        if (prevFormat === null || prevFormat.length !== 0) {
          state.format = format;
        }
      }
    );
    const handleClickSearch = async () => {
      const data = {
        op: "betconfig.getNumConfigInfo",
        params: {
          round_oid: "6294af6bd287173dc57aa9a2",
          bet_option_id: "teng_lang_3",
          number: state.findNumberText,
        },
      };
      const response = await range(data);
      if (response.status === true) {
        state.totalbet = response.result.total_bet;
        state.betMin = response.result.bet_min;
        state.betMax = response.result.bet_max;
        state.maxPerNumber = response.result.max_per_number;
        state.payPerBaht = response.result.reward_step[0].reward;
        response.result.reward_step.forEach((element) => {
          const tableData = {
            condition: "มากกว่า " + element.amt,
            rate: element.reward <= 0 ? "ปิด" : element.reward,
          };
          state.rows.push(tableData);
        });
      }
      console.log(response);
    };
    return {
      ...toRefs(state),
      handleClickSearch,
    };
  },
};
</script>

<style lang="scss" scoped></style>
