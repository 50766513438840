import { request } from "./api";

export function index(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}

export function show(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}

export function poyvoid(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}

export function csv(data) {
  const url = `/webapi/agent`;
  return request("post", url, data, true);
}

export function lottery_group() {
  const url = `/webapi/agent`;
  return request("post", url, { op: "options.lottery_group", params: {} });
}

export function lottery_type(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}
