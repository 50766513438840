import { reactive } from "vue";
import { login } from "@/services/auth-service";
import { useRouter } from "vue-router";

export function useLogin() {
  const router = useRouter();
  const state = reactive({
    count: 0,
    isPwd: true,
    rememberMe: false,
    username: "",
    password: "",
  });
  const onSubmit = async () => {
    const data = {
      op: "system.signin",
      params: {
        username: state.username,
        password: state.password,
        // username: "web008u01",
        // password: "123456",
      },
    };
    console.log(data);
    const response = await login(data);
    console.log("LOGIN", response);
    if (response.status === true) {
      localStorage.setItem("code", response.result.code);
      localStorage.setItem("web", response.result.name);
      router.replace({ name: "InsightIndex" });
    }
  };
  return { state, onSubmit };
}
