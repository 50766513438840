import Swal from "sweetalert2";

export function utilily() {
  const numberWithCommas = (number = null, decimal) => {
    if (!number) {
      return 0;
    }
    const fixedNumber = parseFloat(
      parseFloat(number).toFixed(decimal)
    ).toLocaleString("en-IN", {
      useGrouping: true,
    });
    return String(fixedNumber.replace(/,/g, "")).replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
  };
  const swalAlert = Swal.mixin({
    customClass: {
      container: "swal-container",
      popup: "swal-popup",
      title: "swal-title",
      htmlContainer: "swal-html-container",
      actions: "swal-actions",
      confirmButton: "swal-confirm-btn",
      cancelButton: "swal-cancel-btn",
    },
  });
  const setHeight = (offset) => {
    return { height: offset ? `calc(100vh - ${offset}px)` : "100vh" };
  };
  const timestampToDate = (time) => {
    var newDate = new Date(time);
    const dateStr =
      ("0" + newDate.getDate()).slice(-2) +
      "/" +
      ("0" + (newDate.getMonth() + 1)).slice(-2) +
      "/" +
      (newDate.getFullYear() + 543) +
      " " +
      ("0" + newDate.getHours()).slice(-2) +
      ":" +
      ("0" + newDate.getMinutes()).slice(-2) +
      ":" +
      ("0" + newDate.getSeconds()).slice(-2) +
      "น.";
    return dateStr;
  };
  const exportCsv = (data, name) => {
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(data);
    hiddenElement.target = "_blank";

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = name + ".csv";
    hiddenElement.click();
  };
  return { setHeight, timestampToDate, exportCsv, swalAlert, numberWithCommas };
}
