<template>
  <q-page class="q-pa-md custom-page" :style-fn="setHeight">
    <div class="row q-mb-md" style="height: 5%">
      <div class="flex items-center">
        <span class="title text-h5">หน้าข้อมูลเชิงลึก</span>
        &emsp;
        <q-breadcrumbs>
          <q-breadcrumbs-el label="Admin" />
          <q-breadcrumbs-el label="ข้อมูลเชิงลึก" />
          <q-breadcrumbs-el v-if="tab === 'revenue'" label="ยอดเล่น" />
          <q-breadcrumbs-el
            v-if="tab === 'revenue' && subTab === '1'"
            label="ยอดเล่นทั้งหมด"
          />
          <q-breadcrumbs-el
            v-if="tab === 'revenue' && subTab === '2'"
            label="ยอดได้เสีย"
          />
          <q-breadcrumbs-el
            v-if="tab === 'revenue' && subTab === '3'"
            label="ยอดหนี้"
          />
          <q-breadcrumbs-el v-if="tab === 'transaction'" label="ฝากถอน" />
        </q-breadcrumbs>
      </div>
      <q-space />
      <div class="col-2">
        <q-select
          v-model="model"
          :options="options"
          label="รายเดือน"
          filled
          stack-label
        />
      </div>
    </div>

    <div class="q-gutter-y-md" style="max-width: 25em">
      <q-tabs v-model="tab" dense align="justify">
        <q-tab name="revenue" label="ยอดเล่น"></q-tab>
        <q-tab name="transaction" label="ฝากถอน"></q-tab>
      </q-tabs>
    </div>
    <q-tab-panels v-model="tab">
      <q-tab-panel name="revenue" style="padding-left: 1em; padding-right: 1em">
        <div
          class="container row wrap justify-center q-pa-s q-gutter-md items-center content-center bg-grey-1"
        >
          <q-btn
            padding="1.5rem"
            class="col row"
            style="background-color: #e9f2ff"
            text-color="black"
            size="lg"
            :class="{ activePrimary: subTab == '1' }"
            @click="handleSelectSubTab(1)"
          >
            <div class="col-12" style="font-size: 0.8em">ยอดเล่นทั้งหมด</div>
            <div class="col-12" style="font-size: 1.3em; font-weight: bold">
              {{ totalIncome }} บาท
            </div>
          </q-btn>
          <q-btn
            padding="1.5rem"
            class="col row"
            style="background-color: #c1fec8"
            text-color="black"
            size="lg"
            :class="{ activeSecondary: subTab == '2' }"
            @click="handleSelectSubTab(2)"
          >
            <div class="col-12" style="font-size: 0.8em">ยอดได้เสีย</div>
            <div class="col-12" style="font-size: 1.3em; font-weight: bold">
              {{ totalProfit }} บาท
            </div>
          </q-btn>
          <q-btn
            padding="1.5rem"
            class="col row"
            text-color="black"
            size="lg"
            style="background-color: #ffdddc"
            :class="{ activeRed: subTab == '3' }"
            @click="handleSelectSubTab(3)"
          >
            <div class="col-12" style="font-size: 0.8em">ยอดหนี้</div>
            <div
              class="col-12"
              style="font-size: 1.3em; color: #e40809; font-weight: bold"
            >
              {{ totalDebt }} บาท
            </div>
          </q-btn>
        </div>
        <div v-if="subTab == '1'">
          <BetAmountChart
            :betAmountData="betAmountData"
            :totalBetFreq="totalBetFreq"
          />
        </div>
        <div v-if="subTab == '2'">
          <IncomeLossChart
            :betAmountData="betAmountData"
            :lossAmountData="lossAmountData"
          />
        </div>
        <div v-if="subTab == '3'">
          <DebtChart :debtAmountData="debtAmountData" />
        </div>

        <div
          class="container row wrap justify-center q-pa-s q-gutter-md items-center content-center bg-grey-1"
        >
          <q-btn
            padding="1.5rem"
            class="col row"
            style="background-color: #e9f2ff"
            text-color="black"
            disable
            size="lg"
          >
            <div class="col-12" style="font-size: 0.8em">บิลทั้งหมด</div>
            <div class="col-12" style="font-size: 1.3em; font-weight: bold">
              {{ totalBill }}
            </div>
          </q-btn>
          <q-btn
            padding="1.5rem"
            class="col row"
            style="background-color: #e9f2ff"
            text-color="black"
            @click="handlePoy('win')"
            size="lg"
          >
            <div class="col-12" style="font-size: 0.8em">บิลถูกรางวัล</div>
            <div class="col-12" style="font-size: 1.3em; font-weight: bold">
              {{ rewardedBill }}
            </div>
          </q-btn>
          <q-btn
            padding="1.5rem"
            class="col row"
            style="background-color: #e9f2ff"
            text-color="black"
            @click="handlePoy('loss')"
            size="lg"
          >
            <div class="col-12" style="font-size: 0.8em">บิลเสีย</div>
            <div class="col-12" style="font-size: 1.3em; font-weight: bold">
              {{ lossBill }}
            </div>
          </q-btn>
          <q-btn
            padding="1.5rem"
            class="col row"
            style="background-color: #ffdddc"
            text-color="black"
            size="lg"
            @click="handlePoy('dept')"
          >
            <div class="col-12" style="font-size: 0.8em">บิลหนี้</div>
            <div
              class="col-12"
              style="font-size: 1.3em; color: #e40809; font-weight: bold"
            >
              {{ debtBill }}
            </div>
          </q-btn>
        </div>
      </q-tab-panel>

      <q-tab-panel
        name="transaction"
        style="padding-left: 1em; padding-right: 1em"
      >
        <div
          class="container row wrap justify-center q-pa-s q-gutter-md items-center content-center bg-grey-1"
        >
          <q-btn
            padding="1.5rem"
            class="col row"
            style="background-color: #e9f2ff"
            text-color="black"
            disable
            size="lg"
          >
            <div class="col-12" style="font-size: 0.8em">รายการทั้งหมด</div>
            <div class="col-12" style="font-size: 1.3em; font-weight: bold">
              {{ totalTransaction }} รายการ
            </div>
          </q-btn>
          <q-btn
            padding="1.5rem"
            class="col row"
            style="background-color: #c1fec8"
            text-color="black"
            disable
            size="lg"
          >
            <div class="col-12" style="font-size: 0.8em">รายการฝาก</div>
            <div class="col-12" style="font-size: 1.3em; font-weight: bold">
              {{ totalDepositFreq }} รายการ
            </div>
          </q-btn>
          <q-btn
            padding="1.5rem"
            class="col row"
            style="background-color: #ffdddc"
            text-color="black"
            disable
            size="lg"
          >
            <div class="col-12" style="font-size: 0.8em">รายการถอน</div>
            <div
              class="col-12"
              style="font-size: 1.3em; color: #e40809; font-weight: bold"
            >
              {{ totalWithdrawFreq }} รายการ
            </div>
          </q-btn>
        </div>
        <TransactionChart
          :depositData="depositData"
          :withdrawData="withdrawData"
        />

        <div
          class="container row wrap justify-center q-pa-s q-gutter-md items-center content-center bg-grey-1"
        >
          <q-btn
            padding="1.5rem"
            class="col row"
            style="background-color: #c1fec8"
            text-color="black"
            disable
            size="lg"
          >
            <div class="col-12" style="font-size: 0.8em">ยอดฝากทั้งหมด</div>
            <div class="col-12" style="font-size: 1.3em; font-weight: bold">
              {{ totalDeposit }} บาท
            </div>
          </q-btn>
          <q-btn
            padding="1.5rem"
            class="col row"
            style="background-color: #ffdddc"
            text-color="black"
            disable
            size="lg"
          >
            <div class="col-12" style="font-size: 0.8em">ยอดถอนทั้งหมด</div>
            <div class="col-12" style="font-size: 1.3em; font-weight: bold">
              {{ totalWithdraw }} บาท
            </div>
          </q-btn>
        </div>
      </q-tab-panel>
    </q-tab-panels>
  </q-page>
</template>

<script>
import { toRefs } from "vue";
import { useIndex } from "./composable/use-insight";
import BetAmountChart from "@/components/tables/BetAmountChart.vue";
import IncomeLossChart from "@/components/tables/IncomeLossChart.vue";
import TransactionChart from "@/components/tables/TransactionChart.vue";
import DebtChart from "@/components/tables/DebtChart.vue";

export default {
  components: {
    BetAmountChart,
    IncomeLossChart,
    DebtChart,
    TransactionChart,
  },

  setup() {
    const { state, handleSelectSubTab, handlePoy } = useIndex();

    return {
      ...toRefs(state),
      handleSelectSubTab,
      handlePoy,
    };
  },

  inheritAttrs: false,
};
</script>

<style lang="scss" scoped></style>
