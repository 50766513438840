import { h, resolveComponent } from "vue";
import BillIndex from "@/views/bill/BillIndex.vue";
import BillDetail from "@/views/bill/BillDetail.vue";

const billRoutes = [
  {
    path: "",
    name: "billRoutes",
    component: {
      render: () => h(resolveComponent("router-view")),
    },
    children: [
      {
        path: "",
        name: "BillIndex",
        component: BillIndex,
      },
      {
        path: ":id",
        name: "BillDetail",
        component: BillDetail,
      },
    ],
  },
];

export default billRoutes;
