<template>
  <q-layout view="hhh Lpr lff">
    <q-page-container class="bg-login">
      <q-page class="flex flex-center">
        <q-card class="my-card login" bordered>
          <q-card-section class="flex flex-center">
            <div class="text-h5">ลงชื่อเข้าใช้</div>
          </q-card-section>
          <q-card-section>
            <q-form @submit="onSubmit" class="q-gutter-md">
              <q-input
                filled
                v-model="username"
                label="ชือผู้ใช้"
                lazy-rules
                :rules="[
                  (val) => (val && val.length > 0) || 'กรุณากรอกชื่อผู้ใช้',
                ]"
              >
                <template v-slot:prepend>
                  <q-icon name="person" />
                </template>
              </q-input>

              <q-input
                class="q-mt-none q-pb-none"
                filled
                :type="isPwd ? 'password' : 'text'"
                v-model="password"
                label="รหัสผ่าน"
                lazy-rules
                :rules="[
                  (val) => (val && val.length > 0) || 'กรุณากรอกรหัสผ่าน',
                ]"
              >
                <template v-slot:prepend>
                  <q-icon name="lock" />
                </template>
                <template v-slot:append>
                  <q-icon
                    :name="isPwd ? 'visibility' : 'visibility_off'"
                    class="cursor-pointer"
                    @click="isPwd = !isPwd"
                  />
                </template>
              </q-input>
              <div class="row justify-end items-center q-mt-none">
                <q-checkbox
                  v-model="rememberMe"
                  label="จำบัญชีนี้"
                  color="grey-6"
                />
              </div>
              <div>
                <q-btn
                  class="full-width btn-login"
                  label="เข้าสู่ระบบ"
                  type="submit"
                  test-color="black"
                  size="lg"
                />
              </div>
            </q-form>
          </q-card-section>
        </q-card>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import { toRefs } from "@vue/reactivity";
import { useLogin } from "./composable/use-login";
export default {
  setup() {
    const { state, onSubmit } = useLogin();
    return { ...toRefs(state), onSubmit };
  },
};
</script>

<style lang="scss" scoped></style>
