import { h, resolveComponent } from "vue";
import InsightIndex from "@/views/insight/InsightIndex.vue";

const insightRoutes = [
  {
    path: "",
    name: "InsightRoute",
    component: {
      render: () => h(resolveComponent("router-view")),
    },
    children: [
      {
        path: "",
        name: "InsightIndex",
        component: InsightIndex,
      },
    ],
  },
];

export default insightRoutes;
