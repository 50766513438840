import { onMounted, reactive, computed } from "vue";
import { utilily } from "@/utilities/util";
import { index, show, update, csv } from "@/services/customer-service";
import { useRoute } from "vue-router";

export function useIndex() {
  const { setHeight, exportCsv, numberWithCommas } = utilily();
  const state = reactive({
    options: [
      { id: null, desc: "สถานะทั้งหมด" },
      { id: 200, desc: "ปกติ" },
      { id: 700, desc: "แบน" },
      { id: 500, desc: "ระงับชั่วคราว" },
    ],
    columns: [
      {
        name: "id",
        label: "รหัสผู้ใช้",
        field: "id",
        sortable: true,
        align: "center",
      },
      {
        name: "name",
        label: "ชื่อผู้ใช้",
        field: "name",
        sortable: true,
      },
      {
        name: "balance",
        label: "ยอดเงินคงเหลือ",
        field: "balance",
        sortable: true,
        align: "center",
      },
      {
        name: "level",
        label: "ระดับผูใช้",
        field: "level",
        sortable: true,
        align: "center",
      },
      {
        name: "status",
        label: "สถานะ",
        field: "status",
        sortable: true,
        align: "center",
      },
      {
        name: "edit",
        label: "แก้ไข",
        field: "edit",
        sortable: true,
        align: "center",
      },
    ],
    rows: [
      // {
      //   id: "001",
      //   name: "NateMohammed",
      //   balance: "500",
      //   level: "ทั่วไป",
      //   status: "ปกติ",
      //   edit: "",
      // },
    ],
    pagination: {
      page: 1,
      rowsPerPage: 25,
    },
    pagesNumber: computed(() =>
      Math.ceil(state.total / state.pagination.rowsPerPage)
    ),
    total: 0,
    pagesAlready: [],
    loading: false,
    customerId: "",
    selectStatus: null,
  });
  const handleExportBtn = async () => {
    const sortData = provideData();
    const data = {
      op: "customer.csv",
      params: {
        where: {
          ...sortData,
        },
      },
    };
    const response = await csv(data);
    exportCsv(response, "รายการผู้ใช้");
  };
  const setRowsData = (response, offset) => {
    var index = offset !== null ? offset : 0;
    console.log(index);
    state.total = response.total;
    response.data.forEach((element) => {
      const prepareData = {
        id: element.uniqid,
        name: element.username,
        balance: numberWithCommas(element.wallet.balance),
        level: element.level_text,
        status: element.status,
        edit: "",
      };
      state.rows[index] = prepareData;
      console.log(state.rows[index]);
      index++;
    });
  };
  const provideData = () => {
    const customerId =
      state.customerId !== "" ? { uniqid: state.customerId } : null;
    const selectStatus =
      state.selectStatus !== null && state.selectStatus.id !== null
        ? { status: state.selectStatus.id }
        : null;
    const sort = {
      ...customerId,
      ...selectStatus,
    };
    return sort;
  };
  const handleClickPagination = () => {
    submitSortCustomer(
      (state.pagination.page - 1) * state.pagination.rowsPerPage
    );
  };
  const handleClickSubmit = () => {
    submitSortCustomer();
  };
  const submitSortCustomer = async (offset = null) => {
    if (
      state.pagesAlready.length !== 0 &&
      state.pagesAlready.includes(state.pagination.page)
    ) {
      return;
    }
    var offsetNum = null;
    if (offset !== null) {
      offsetNum = offset !== 0 ? offset : 1;
    }
    state.loading = true;
    const sortData = provideData();
    const page = state.pagination.page;
    const data = {
      op: "customer.index",
      params: {
        where: {
          ...sortData,
        },
        limit: 25,
        offset: offsetNum === null ? undefined : offsetNum,
        page: page,
      },
    };
    console.log(data);
    const response = await index(data);
    if (response.status === true) {
      state.pagesAlready.push(page);
      if (offset === null) {
        state.rows = [];
      }
      setRowsData(response.result, offset);
      state.pagination.page = page;
    }
    state.loading = false;
    console.log(response);
  };
  return {
    state,
    setHeight,
    submitSortCustomer,
    handleExportBtn,
    handleClickPagination,
    handleClickSubmit,
  };
}

export function useDetail() {
  const route = useRoute();
  const { setHeight, timestampToDate, numberWithCommas } = utilily();
  const state = reactive({
    footerColumns: [
      {
        name: "date",
        label: "สถิติการเดิมพัน",
        field: "date",
        sortable: false,
        align: "center",
      },
      {
        name: "amount",
        label: "จำนวนบิล",
        field: "amount",
        sortable: false,
        align: "center",
      },
      {
        name: "price",
        label: "จำนวนเงิน",
        field: "price",
        sortable: false,
        align: "center",
      },
    ],
    footerRows: [],
    customerDetail: null,
    pagination: 6,
  });
  onMounted(() => {
    prepareCustomerData();
  });
  const handleClickUpdate = async (status) => {
    const data = {
      op: "customer.update",
      params: {
        uniqid: state.customerDetail.uniqid,
        status: status,
      },
    };
    const response = await update(data);
    if (response.status === true) {
      state.customerDetail.status = status;
    }
    console.log(response);
  };
  const prepareCustomerData = async () => {
    const data = {
      op: "customer.show",
      params: {
        uniqid: route.params.id,
      },
    };
    const response = await show(data);
    if (response.status === true) {
      state.customerDetail = response.result;
      for (const key in response.result.statistic.duration) {
        console.log(key);
        const appendData = {
          date:
            key === "3d"
              ? "3 วันล่าสุด"
              : key === "7d"
              ? "7 วันล่าสุด"
              : key === "30d"
              ? "30 วันล่าสุด"
              : key === "90d"
              ? "90 วันล่าสุด"
              : key === "180d"
              ? "180 วันล่าสุด"
              : "unknown",
          amount: numberWithCommas(response.result.statistic.duration[key][0]),
          price: numberWithCommas(response.result.statistic.duration[key][1]),
        };
        if (key === "3d" || key === "7d") {
          state.footerRows.unshift(appendData);
        } else {
          state.footerRows.push(appendData);
        }
      }
      const appendData = {
        date: "ทั้งหมด",
        amount: response.result.statistic.total.data[0],
        price: response.result.statistic.total.data[1],
      };
      state.footerRows.push(appendData);
      console.log(state.footerRows);
      state.customerDetail.wallet.last_deposit_time = timestampToDate(
        state.customerDetail.wallet.last_deposit_time * 1000
      );
      state.customerDetail.wallet.last_withdraw_time = timestampToDate(
        state.customerDetail.wallet.last_withdraw_time * 1000
      );
    }
    console.log(response);
  };
  return { state, setHeight, handleClickUpdate, numberWithCommas };
}
