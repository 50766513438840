<template>
  <div>
    <highcharts
      class="hc highcharts-figure"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>
import { defineComponent } from "vue";
// import Highcharts from "highcharts";
export default defineComponent({
  name: "transactionChart",
  data(instance) {
    return {
      chartOptions: {
        chart: {
          type: "column",
          marginLeft: 150,
          marginRight: 150,
          spacingTop: 30,
          spacingBottom: 30,
        },
        title: {
          text: undefined,
        },
        xAxis: {
          type: "category",
          labels: {
            rotation: -45,
          },
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "จำนวนเงิน",
          },
          labels: {
            format: "{value:,.0f}",
          },
          softMax: 1000,
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:,.0f} บาท</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: [
          {
            name: "ฝาก",
            data: instance.depositData,
          },
          {
            name: "ถอน",
            data: instance.withdrawData,
          },
        ],
      },
    };
  },
  props: {
    depositData: Array,
    withdrawData: Array,
  },
  watch: {
    depositData: {
      handler: function (val) {
        this.$data.chartOptions.series[0].data = val;
      },
    },
    withdrawData: {
      handler: function (val) {
        this.$data.chartOptions.series[1].data = val;
      },
    },
  },
});
</script>
