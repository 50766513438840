import { h, resolveComponent } from "vue";
import ChangePassword from "@/views/login/ChangePassword.vue";

const numberedRoutes = [
  {
    path: "",
    name: "ChangePasswordRoutes",
    component: {
      render: () => h(resolveComponent("router-view")),
    },
    children: [
      {
        path: "",
        name: "ChangePasswordIndex",
        component: ChangePassword,
      },
    ],
  },
];

export default numberedRoutes;
