import { createRouter, createWebHistory } from "vue-router";
import DefaultLayout from "../layouts/DefaultLayout.vue";
// import defaultRoutes from "./default/default-route.js";
import insightRoutes from "./insight/insight-route.js";
import numberedRoutes from "./numbered/numbered-route.js";
import billRoutes from "./bill/bill-route.js";
import customerRoutes from "./customer/customer-route.js";
import financialRoutes from "./financial/financial-route.js";
import changepasswordRoutes from "./changepassword/changepassword-route.js";
import Login from "../views/login/Login.vue";

const routes = [
  {
    path: "/",
    name: "Insight",
    component: DefaultLayout,
    meta: { requireAuth: true },
    children: [...insightRoutes],
  },
  {
    path: "/numbered",
    name: "Numbered",
    component: DefaultLayout,
    meta: { requireAuth: true },
    children: [...numberedRoutes],
  },
  {
    path: "/bill",
    name: "Bill",
    component: DefaultLayout,
    meta: { requireAuth: true },
    children: [...billRoutes],
  },
  {
    path: "/customer",
    name: "Customer",
    component: DefaultLayout,
    meta: { requireAuth: true },
    children: [...customerRoutes],
  },
  {
    path: "/financial",
    name: "Financial",
    component: DefaultLayout,
    meta: { requireAuth: true },
    children: [...financialRoutes],
  },
  {
    path: "/changepassword",
    name: "ChangePassword",
    component: DefaultLayout,
    children: [...changepasswordRoutes],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    const auth = localStorage.getItem("code");
    console.log(auth);
    if (auth === null) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
