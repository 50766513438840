import { h, resolveComponent } from "vue";
import FinancialIndex from "@/views/financial/FinancialIndex.vue";
import FinancialDetail from "@/views/financial/FinancialDetail.vue";

const financialRoutes = [
  {
    path: "",
    name: "financialRoutes",
    component: {
      render: () => h(resolveComponent("router-view")),
    },
    children: [
      {
        path: "",
        name: "FinancialIndex",
        component: FinancialIndex,
      },
      {
        path: ":id",
        name: "FinancialDetail",
        component: FinancialDetail,
      },
    ],
  },
];

export default financialRoutes;
