import { onMounted, reactive, ref, watch } from "vue";
import { getRevenue } from "@/services/insight-service";
import { getTransaction } from "../../../services/insight-service";
import { useRouter } from "vue-router";
export function useIndex() {
  const router = useRouter();
  const state = reactive({
    page: "insight",
    model: ref(null),
    tab: ref("revenue"),
    subTab: "1",
    options: null,
    betAmountData: [],
    lossAmountData: [],
    debtAmountData: [],
    depositData: [],
    withdrawData: [],
    totalWithdraw: 0,
    totalDeposit: 0,
    totalTransaction: 0,
    totalWithdrawFreq: 0,
    totalDepositFreq: 0,
    totalIncome: 0,
    totalProfit: 0,
    totalDebt: 0,
    totalBetFreq: [],
    totalBill: 0,
    rewardedBill: 0,
    lossBill: 0,
    debtBill: 0,
    selectedDate: [],
    refDaysOfMonth: 0,
  });

  watch(
    () => state.model,
    async (options) => {
      let match = options.split(/ /g);
      let monthIndex = months.indexOf(match[0]);
      let start_day = new Date(match[1], monthIndex, 1).getTime() / 1000;
      let last_day = new Date(match[1], monthIndex + 1, 1).getTime() / 1000;
      state.refDaysOfMonth = new Date(match[1], monthIndex + 1, 0);
      state.selectedDate = [start_day, last_day];
      await initRequest(start_day, last_day, match[0]);
    }
  );

  const months = [
    "ม.ค.",
    "ก.พ.",
    "มี.ค.",
    "เม.ย.",
    "พ.ค.",
    "มิ.ย.",
    "ก.ค.",
    "ส.ค.",
    "ก.ย.",
    "ต.ค.",
    "พ.ย.",
    "ธ.ค.",
  ];
  onMounted(async () => {
    await generateMonths();
  });

  const initRequest = async (
    start_day = null,
    last_day = null,
    month = null
  ) => {
    const data1 = {
      op: "summary.poys",
      params: {
        start_day,
        last_day,
      },
    };
    const data2 = {
      op: "summary.transactions",
      params: {
        start_day,
        last_day,
      },
    };
    const response1 = await getRevenue(data1);
    const response2 = await getTransaction(data2);
    let result = response1.result;
    arrangeRevenue(result, month);
    result = response2.result;
    arrangeTransaction(result, month);
  };
  const arrangeRevenue = async (result, month) => {
    result.sort((a, b) => {
      if (a._id < b._id) return -1;
      if (a._id > b._id) return 1;
      return 0;
    });
    let revenueArr = await result.map((item) => [
      parseInt(item["_id"]) + " " + month,
      item["sum_withdraw_value"],
    ]);
    revenueArr = genEmptyDate(revenueArr, month);
    let lossArr = result.map((item) => [
      parseInt(item["_id"]) + " " + month,
      item["sum_deposit_value"],
    ]);
    lossArr = genEmptyDate(lossArr, month);
    let profitArr = result.map(
      (item) =>
        parseInt(item["sum_withdraw_value"]) -
        parseInt(item["sum_deposit_value"])
    );
    let debtArr = result.map((item) => [
      parseInt(item["_id"]) + " " + month,
      item["sum_debt_value"],
    ]);
    debtArr = genEmptyDate(debtArr, month);
    let betFreq = result.map((item) => [
      parseInt(item["_id"]) + " " + month,
      parseInt(item["count_loss"]) + parseInt(item["count_loss"]),
    ]);
    betFreq = genEmptyDate(betFreq, month);
    state.betAmountData = revenueArr;
    state.totalIncome = formatMoney(
      revenueArr.reduce((a, b) => {
        return a + b[1];
      }, 0)
    );
    state.totalProfit = formatMoney(profitArr.reduce((a, b) => a + b, 0));
    state.totalDebt = formatMoney(debtArr.reduce((a, b) => a + b[1], 0));
    state.lossAmountData = lossArr;
    state.debtAmountData = debtArr;
    state.totalBetFreq = betFreq;
    state.totalBill = formatMoney(betFreq.reduce((a, b) => a + b[1], 0));
    state.rewardedBill = result
      .map((item) => parseInt(item["count_reward"]))
      .reduce((a, b) => a + b, 0);
    state.lossBill = result
      .map((item) => parseInt(item["count_loss"]))
      .reduce((a, b) => a + b, 0);
    state.debtBill = result
      .map(
        (item) =>
          parseInt(item["count_debt"] ? item["count_debt"] : 0) +
          parseInt(item["count_debt_void"] ? item["count_debt_void"] : 0)
      )
      .reduce((a, b) => a + b, 0);
    state.rewardedBill = formatMoney(state.rewardedBill);
    state.lossBill = formatMoney(state.lossBill);
    state.debtBill = formatMoney(state.debtBill);
  };
  const arrangeTransaction = (result, month) => {
    result.sort((a, b) => {
      if (a._id < b._id) return -1;
      if (a._id > b._id) return 1;
      return 0;
    });
    let transactionFreq = result.map(
      (item) =>
        parseInt(item["count_withdraw"]) + parseInt(item["count_deposit"])
    );

    let depositArr = result.map((item) => [
      parseInt(item["_id"]) + " " + month,
      item["sum_deposit_value"],
    ]);
    depositArr = genEmptyDate(depositArr, month);
    let withdrawArr = result.map((item) => [
      parseInt(item["_id"]) + " " + month,
      item["sum_withdraw_value"],
    ]);
    withdrawArr = genEmptyDate(withdrawArr, month);
    state.depositData = depositArr;
    state.withdrawData = withdrawArr;
    state.totalDeposit = formatMoney(
      depositArr.reduce((a, b) => {
        return a + b[1];
      }, 0)
    );
    state.totalWithdraw = formatMoney(
      withdrawArr.reduce((a, b) => {
        return a + b[1];
      }, 0)
    );

    state.totalWithdrawFreq = formatMoney(
      result
        .map((item) => parseInt(item["count_withdraw"]))
        .reduce((a, b) => a + b, 0)
    );
    state.totalDepositFreq = formatMoney(
      result
        .map((item) => parseInt(item["count_deposit"]))
        .reduce((a, b) => a + b, 0)
    );
    state.totalTransaction = formatMoney(
      transactionFreq.reduce((a, b) => a + b, 0)
    );
  };
  const generateMonths = async () => {
    let month = [
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ];

    const d = new Date();
    let monthInPrevYear = month.splice(d.getMonth());
    month = month.map((num) => {
      return num + " " + d.getFullYear();
    });
    let curMonth = monthInPrevYear.shift() + " " + d.getFullYear();

    monthInPrevYear = monthInPrevYear.map((num) => {
      return num + " " + (d.getFullYear() - 1);
    });
    month.reverse();
    monthInPrevYear.reverse();
    const sorted = [curMonth, ...month.concat(monthInPrevYear)];

    state.options = sorted;
    state.model = sorted[0];
  };
  const handleSelectSubTab = (num) => {
    state.subTab = num.toString();
  };
  const handlePoy = (type) => {
    router.push({
      path: "/bill",
      query: {
        type: type,
        start: state.selectedDate[0],
        end: state.selectedDate[1],
      },
      params: {
        type: type,
        start: state.selectedDate[0],
        end: state.selectedDate[1],
      },
    });
  };
  const formatMoney = (
    amount,
    decimalCount = 0,
    decimal = ".",
    thousands = ","
  ) => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    } catch (e) {
      return amount;
    }
  };
  const genEmptyDate = (arr, month) => {
    /**
     * ทำ Array = [['1 พ.ค.', 213],['2 พ.ค.', 362],...]
     * เป็น Obj = {
     *    '1 พ.ค.': 213,
     *    '2 พ.ค.': 362,
     *     ....
     * }
     *  */
    let obj = arr.reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[1] }), {});
    // get จำนวนวันของเดือนนั้นๆ
    let refDate = state.refDaysOfMonth.getDate();
    // วนเช็คว่า วันไหนที่ข้อมูลว่างบ้าง
    for (let i = 0; i < parseInt(refDate); i++) {
      let key = `${i + 1} ${month}`;
      if (!obj[key]) obj[key] = 0;
    }
    // sort obj จาก key '1 พ.ค.', '2 พ.ค.', .....
    const ordered = Object.keys(obj)
      .sort((a, b) => {
        let match1 = a.split(/ /g);
        let match2 = b.split(/ /g);
        if (parseInt(match1[0]) > parseInt(match2[0])) return 1;
        if (parseInt(match1[0]) < parseInt(match2[0])) return -1;
        return 0;
      })
      .reduce((newObj, key) => {
        newObj[key] = obj[key];
        return newObj;
      }, {});

    // เปลี่ยน Obj กลับเป็น Array เพื่อที่จะใช้ใน highcharts ได้
    let newArr = [];
    for (const key in ordered) {
      newArr.push([key, obj[key]]);
    }
    return newArr;
  };
  return { state, handleSelectSubTab, formatMoney, handlePoy };
}
