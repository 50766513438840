<template>
  <q-page class="q-pa-md custom-page" :style-fn="setHeight">
    <div class="row q-mb-md">
      <div class="flex items-center">
        <span class="title text-h5">หน้ารายการผู้ใช้</span>
        &emsp;
        <q-breadcrumbs>
          <q-breadcrumbs-el label="Admin" />
          <q-breadcrumbs-el label="รายการผู้ใช้" />
        </q-breadcrumbs>
      </div>
      <q-space />
      <div class="flex">
        <q-input v-model="customerId" label="ระบุรหัสผู้ใช้" outlined dense />
        &emsp;
        <q-select
          style="width: 188px"
          v-model="selectStatus"
          :options="options"
          option-value="id"
          option-label="desc"
          label="เลือกสถานะ"
          filled
          dense
          options-dense
        />
        &emsp;
        <q-btn
          @click="handleClickSubmit"
          color="primary"
          label="ค้นหา"
          unelevated
        />
        &emsp;
        <q-btn
          @click="handleExportBtn"
          style="background: #f2f2f2"
          text-color="black"
          icon-right="download"
          label="ส่งออก"
          unelevated
        />
      </div>
    </div>
    <q-table
      class="sticky-virtscroll-table customer-table"
      table-class="text-grey-8"
      table-header-class="text-black"
      :table-header-style="{ backgroundColor: '#B0CEFC' }"
      :rows="rows"
      :columns="columns"
      row-key="id"
      virtual-scroll
      v-model:pagination="pagination"
      :rows-per-page-options="[0]"
      hide-pagination
      :loading="loading"
    >
      <template v-slot:loading>
        <q-inner-loading class="q-mt-xl" showing color="primary" />
      </template>
      <template v-slot:body-cell-status="props">
        <q-td key="status" :props="props">
          <span
            :class="
              props.value === 200
                ? 'text-green'
                : props.value === 500
                ? 'text-yellow'
                : 'text-red'
            "
            >{{
              props.value === 200
                ? "ปกติ"
                : props.value === 500
                ? "ระงับชั่วคราว"
                : props.value === 700
                ? "แบน"
                : "unknown"
            }}</span
          >
        </q-td>
      </template>
      <template v-slot:body-cell-edit="props">
        <q-td key="edit" :props="props">
          <router-link
            :to="{ name: 'CustomerDetail', params: { id: props.key } }"
          >
            <q-icon name="visibility" size="21px" />
          </router-link>
        </q-td>
      </template>
    </q-table>
    <div class="row justify-end q-mt-md">
      <q-pagination
        @click="handleClickPagination"
        v-model="pagination.page"
        color="grey-8"
        :max="pagesNumber"
        :max-pages="6"
        boundary-numbers
        size="md"
      />
    </div>
  </q-page>
</template>

<script>
import { toRefs } from "vue";
import { useIndex } from "./composable/use-customer";

export default {
  setup() {
    const {
      state,
      submitSortCustomer,
      setHeight,
      handleExportBtn,
      handleClickPagination,
      handleClickSubmit,
    } = useIndex();

    return {
      ...toRefs(state),
      submitSortCustomer,
      setHeight,
      handleExportBtn,
      handleClickPagination,
      handleClickSubmit,
    };
  },
};
</script>

<style lang="scss" scoped></style>
