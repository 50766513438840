<template>
  <q-page class="q-pa-md custom-page" :style-fn="setHeight">
    <div class="row q-mb-md" style="height: 5%">
      <div class="flex items-center">
        <span class="title text-h5">หน้ารายละเอียดบิล</span>
        &emsp;
        <q-breadcrumbs>
          <q-breadcrumbs-el label="Admin" />
          <q-breadcrumbs-el label="รายการบิล" />
          <q-breadcrumbs-el label="รายละเอียดบิล" />
        </q-breadcrumbs>
      </div>
      <q-space />
      <div class="flex items-center">
        <span class="title text-h5"
          >รหัสบิล : {{ billDetail !== null ? billDetail.uniqid : "" }}
        </span>
        &emsp;
        <q-btn
          @click="confirmDialog = true"
          color="negative"
          label="ยกเลิกบิล"
          :disabled="
            billDetail !== null && billDetail.status >= 700 ? true : false
          "
          unelevated
        />
      </div>
    </div>
    <div class="row q-mb-md q-col-gutter-y-md q-col-gutter-x-md" style="">
      <div class="col-5">
        <q-card class="billCard" style="background-color: #e9f2ff">
          <q-card-section horizontal>
            <q-card-section class="col-4 text-grey text-subtitle1">
              ชื่อผู้ใช้
            </q-card-section>
            <q-card-section class="col-8 text-body1">
              {{ billDetail !== null ? billDetail.customer.username : "-" }}
            </q-card-section>
          </q-card-section>
          <q-card-section horizontal>
            <q-card-section class="col-4 text-grey text-subtitle1">
              รหัสผู้ใช้
            </q-card-section>
            <q-card-section class="col-8 text-body1">
              {{ billDetail !== null ? billDetail.customer.uniqid : "-" }}
            </q-card-section>
          </q-card-section>
          <q-card-section horizontal>
            <q-card-section class="col-4 text-grey text-subtitle1">
              เวลาสร้าง
            </q-card-section>
            <q-card-section class="col-8 text-body1">
              {{
                billDetail !== null
                  ? timestampToDate(billDetail.created_at)
                  : "-"
              }}
            </q-card-section>
          </q-card-section>
          <q-card-section horizontal>
            <q-card-section class="col-4 text-grey text-subtitle1">
              เวลาออกผล
            </q-card-section>
            <q-card-section class="col-8 text-body1">
              {{
                billDetail !== null
                  ? timestampToDate(billDetail.round.round_final_dt)
                  : "-"
              }}
            </q-card-section>
          </q-card-section>
          <q-card-section horizontal>
            <q-card-section class="col-4 text-grey text-subtitle1">
              สถานะ
            </q-card-section>
            <q-card-section class="col-8 text-body1">
              {{ billDetail !== null ? billDetail.status_text : "-" }}
            </q-card-section>
          </q-card-section>
          <q-card-section horizontal>
            <q-card-section class="col-4 text-grey text-subtitle1">
              ประเภทหวย
            </q-card-section>
            <q-card-section class="col-8 text-body1">
              {{ billDetail !== null ? billDetail.lotterys.name : "-" }}
            </q-card-section>
          </q-card-section>
          <q-card-section horizontal>
            <q-card-section class="col-4 text-grey text-subtitle1">
              รายการ
            </q-card-section>
            <q-card-section class="col-8 text-body1">
              {{ billDetail !== null ? billDetail.round.bet_name : "-" }}
            </q-card-section>
          </q-card-section>
          <q-card-section horizontal>
            <q-card-section class="col-4 text-grey text-subtitle1">
              รอบ
            </q-card-section>
            <q-card-section class="col-8 text-body1">
              {{ billDetail !== null ? billDetail.round.bet_round : "-" }}
            </q-card-section>
          </q-card-section>
          <q-card-section horizontal>
            <q-card-section class="col-4 text-grey text-subtitle1">
              จำนวนเงิน
            </q-card-section>
            <q-card-section class="col-8 text-body1">
              {{
                billDetail !== null
                  ? numberWithCommas(billDetail.total_price)
                  : "-"
              }}
            </q-card-section>
          </q-card-section>
          <q-card-section horizontal>
            <q-card-section class="col-4 text-grey text-subtitle1">
              ยอดได้เสีย
            </q-card-section>
            <q-card-section class="col-8 text-body1">
              {{
                billDetail !== null
                  ? numberWithCommas(billDetail.total_reward)
                  : "-"
              }}
            </q-card-section>
          </q-card-section>
          <q-card-section horizontal>
            <q-card-section class="col-4 text-grey text-subtitle1">
              ยอดหนี้
            </q-card-section>
            <q-card-section class="col-8 text-body1">
              {{
                billDetail !== null
                  ? numberWithCommas(billDetail.dept_value)
                  : "-"
              }}
            </q-card-section>
          </q-card-section>
        </q-card>
      </div>
      <div class="col-7" style="max-height: 500px">
        <q-table
          class="sticky-virtscroll-table bill-detail-table"
          table-class="text-grey-8"
          table-header-class="text-black"
          :table-header-style="{ backgroundColor: '#B0CEFC' }"
          :rows="rows"
          :columns="columns"
          row-key="select"
          virtual-scroll
          v-model:pagination="pagination"
          :rows-per-page-options="[0]"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <q-table
          class="footer-table"
          table-class="text-grey-8"
          table-header-class="text-black"
          :table-header-style="{ backgroundColor: '#DDDDDD' }"
          :rows="footerRows"
          :columns="footerColumns"
          row-key="type"
          hide-bottom
        >
        </q-table>
      </div>
    </div>
    <q-dialog v-model="confirmDialog" class="dialog" persistent>
      <q-card class="alert">
        <q-card-section class="row items-center justify-center q-px-md">
          <span class="q-ml-sm">
            ยืนยันการยกเลิกบิลรหัส:
            {{ billDetail !== null ? billDetail.uniqid : "" }}
          </span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            label="ยกเลิก"
            color="white"
            text-color="black"
            v-close-popup
          />
          <q-btn
            @click="handleClickCancel"
            label="ยืนยัน"
            color="red"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="successAlert" class="dialog" persistent>
      <q-card class="alert bg-light-green-14 text-white">
        <q-card-section class="row items-center justify-center q-px-lg">
          <span class="q-ml-sm text-subtitle1"> ยกเลิกเรียบร้อย </span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn label="ปิด" color="white" text-color="black" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import { useDetail } from "./composable/use-bill";
import { toRefs } from "@vue/reactivity";

export default {
  name: "BillDetail",
  setup() {
    const {
      state,
      setHeight,
      handleClickCancel,
      numberWithCommas,
      timestampToDate,
    } = useDetail();

    return {
      ...toRefs(state),
      setHeight,
      handleClickCancel,
      numberWithCommas,
      timestampToDate,
    };
  },
};
</script>

<style lang="scss" scoped></style>
