import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import { highChartOptions } from "./configs/setOptions";
createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .use(HighchartsVue)
  .mount("#app");

Highcharts.setOptions(highChartOptions);
