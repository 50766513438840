import { onMounted, reactive } from "vue";
import { utilily } from "@/utilities/util";
import {
  poyType,
  poy,
  round,
  format,
  indexList,
} from "@/services/numbered-service";

export function useIndex() {
  const { setHeight, numberWithCommas } = utilily();
  const state = reactive({
    columns: [
      {
        name: "id",
        label: "ลำดับ",
        field: "id",
        sortable: false,
        align: "center",
      },
      {
        name: "number",
        label: "เลข",
        field: "number",
        sortable: false,
        align: "center",
      },
      {
        name: "range",
        label: "ช่วงราคาที่แทงได้",
        field: "range",
        sortable: false,
        align: "center",
      },
      {
        name: "amount",
        label: "มากสุดต่อคน",
        field: "amount",
        sortable: false,
        align: "center",
      },
      {
        name: "result",
        label: "จ่ายรางวัล",
        field: "result",
        sortable: false,
        align: "center",
      },
      {
        name: "status",
        label: "สถานะ",
        field: "status",
        sortable: false,
        align: "center",
      },
      {
        name: "other",
        label: "",
        field: "other",
        sortable: false,
        align: "center",
      },
    ],
    rows: [],
    optionsPoyType: [],
    optionsPoy: [],
    optionsRound: [],
    optionsFormat: [],
    pagination: { rowsPerPage: 10 },
    rangeBtn: false,
    addBtn: false,
    sumBtn: false,
    selectPoyType: null,
    selectPoy: null,
    selectRound: null,
    selectFormat: null,
    hideBtn: true,
    dialogType: "",
  });
  onMounted(() => {
    getPoyTypeData();
  });
  const getPoyTypeData = async () => {
    const data = {
      op: "betconfig.getGroupInfo",
    };
    const response = await poyType(data);
    if (response.status === true) {
      for (const key in response.result) {
        const data = { id: key, desc: response.result[key].name };
        state.optionsPoyType.push(data);
      }
    }
  };
  const getPoyData = async (val) => {
    state.selectPoyType = val;
    const data = {
      op: "betconfig.getLotteryList",
      params: {
        lottery_group_id: state.selectPoyType.id,
      },
    };
    const response = await poy(data);
    if (response.status === true) {
      state.optionsPoy = [];
      response.result.forEach((element) => {
        const data = { id: element._id, desc: element.name };
        state.optionsPoy.push(data);
      });
    }
  };
  const getRoundData = async (val) => {
    state.selectPoy = val;
    const data = {
      op: "betconfig.getLotteryRoundList",
      params: {
        lottery_oid: state.selectPoy.id,
      },
    };
    const response = await round(data);
    if (response.status === true) {
      state.optionsRound = [];
      response.result.forEach((element) => {
        const data = { id: element._id, desc: element.round_name };
        state.optionsRound.push(data);
      });
    }
  };
  const getFormatData = async (val) => {
    state.selectRound = val;
    const data = {
      op: "betconfig.getLotteryBetOptions",
      params: {
        round_oid: state.selectRound.id,
      },
    };
    const response = await format(data);
    if (response.status === true) {
      state.optionsFormat = [];
      response.result.forEach((element) => {
        const data = { id: element.id, desc: element.name };
        state.optionsFormat.push(data);
      });
    }
  };
  const getIndexList = (val) => {
    state.selectFormat = val;
    requestList();
  };
  const requestList = async () => {
    const data = {
      op: "betconfig.getBetConfigList",
      params: {
        round_oid: state.selectRound.id,
        bet_option_id: state.selectFormat.id,
      },
    };
    console.log(data);
    const response = await indexList(data);
    console.log(response);
    if (response.status === true) {
      state.rows = [];
      response.result.reverse().forEach((element, index) => {
        var rewardArray = [];
        element.reward_step.forEach((element) => {
          const rewardData = {
            amt: element.amt,
            reward: element.reward,
          };
          rewardArray.push(rewardData);
        });
        const data = {
          id: index + 1,
          uniqid: element.uniqid,
          number: element.for_numbers.join(),
          range: "",
          min: element.bet_limit.minBet,
          max: element.bet_limit.maxBet,
          amount: element.max_per_number,
          result: element.reward_step.length,
          reward: rewardArray,
          status: element.status,
          other: "",
        };
        state.rows.push(data);
      });
      console.log(state.rows);
      state.hideBtn = false;
    }
  };
  const handleClickEdit = (key) => {
    state.dialogType = key;
    state.addBtn = true;
  };
  const handleCloseAddDialog = () => {
    state.dialogType = "";
    state.addBtn = false;
  };
  return {
    state,
    setHeight,
    getPoyData,
    getRoundData,
    getFormatData,
    getIndexList,
    requestList,
    handleClickEdit,
    numberWithCommas,
    handleCloseAddDialog,
  };
}
