<template>
  <q-page class="q-pa-md custom-page" :style-fn="setHeight">
    <div class="row q-mb-md">
      <div class="flex items-center">
        <span class="title text-h5">หน้ารายการฝากถอน</span>
        &emsp;
        <q-breadcrumbs>
          <q-breadcrumbs-el label="Admin" />
          <q-breadcrumbs-el label="รายการฝากถอน" />
        </q-breadcrumbs>
      </div>
    </div>
    <div
      class="row items-center justify-end q-mb-md q-col-gutter-y-md q-col-gutter-x-md"
    >
      <div class="col-2">
        <q-input v-model="uniqId" label="ระบุรหัสฝากถอน" outlined dense />
      </div>
      <div class="col-2">
        <q-input v-model="customerId" label="ระบุชื่อผู้ใช้" outlined dense />
      </div>
      <div class="col-2">
        <q-select
          v-model="selectStatus"
          :options="optionsStatus"
          option-value="id"
          option-label="desc"
          label="เลือกสถานะ"
          filled
          dense
          options-dense
        />
      </div>
      <div class="col-2">
        <q-select
          v-model="selectList"
          :options="optionsLists"
          option-value="id"
          option-label="desc"
          label="เลือกรายการ"
          filled
          dense
          options-dense
        />
      </div>
      <div class="col-1">
        <q-btn
          @click="handleClickSubmit()"
          class="full-width"
          color="primary"
          label="ค้นหา"
        />
      </div>
    </div>
    <q-table
      class="sticky-virtscroll-table financial-table"
      table-class="text-grey-8"
      table-header-class="text-black"
      :table-header-style="{ backgroundColor: '#B0CEFC' }"
      :rows="rows"
      :columns="columns"
      row-key="id"
      virtual-scroll
      v-model:pagination="pagination"
      :rows-per-page-options="[0]"
      hide-pagination
      :loading="loading"
    >
      <template v-slot:header-cell="props">
        <q-th
          @click="handleSort(props.col.name)"
          :class="[
            props.col.name === 'list' || props.col.name === 'date'
              ? 'cursor-pointer'
              : '',
          ]"
          :props="props"
        >
          {{ props.col.label }}
        </q-th>
      </template>
      <template v-slot:loading>
        <q-inner-loading class="q-mt-xl" showing color="primary" />
      </template>
      <template v-slot:body-cell-list="props">
        <q-td key="list" :props="props">
          <span
            :class="
              props.value === 100
                ? 'text-green'
                : props.value === 200
                ? 'text-red'
                : ''
            "
            >{{
              props.value === 100
                ? "ฝาก"
                : props.value === 200
                ? "ถอน"
                : "Unknown"
            }}</span
          >
        </q-td>
      </template>
      <template v-slot:body-cell-other="props">
        <q-td key="other" :props="props">
          <router-link
            :to="{ name: 'FinancialDetail', params: { id: props.value } }"
          >
            <q-icon name="visibility" size="21px" />
          </router-link>
        </q-td>
      </template>
    </q-table>
    <div class="row justify-end q-mt-md">
      <q-pagination
        @click="handleClickPagination"
        v-model="pagination.page"
        color="grey-8"
        :max="pagesNumber"
        :max-pages="6"
        boundary-numbers
        size="md"
      />
    </div>
  </q-page>
</template>

<script>
import { toRefs } from "vue";
import { useIndex } from "./composable/use-financial";

export default {
  setup() {
    const {
      state,
      setHeight,
      submitSortFinancial,
      handleSort,
      handleClickPagination,
      handleClickSubmit,
    } = useIndex();

    return {
      ...toRefs(state),
      setHeight,
      submitSortFinancial,
      handleSort,
      handleClickPagination,
      handleClickSubmit,
    };
  },
};
</script>

<style lang="scss" scoped></style>
