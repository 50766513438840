import { request } from "./api";

export function getRevenue(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}
export function getTransaction(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}
