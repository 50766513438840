import { onMounted, reactive, watch, computed } from "vue";
import {
  lottery_group,
  lottery_type,
  index,
  show,
  poyvoid,
  csv,
} from "@/services/bill-service";
import { useRoute } from "vue-router";
import { utilily } from "@/utilities/util";

export function useIndex() {
  const { setHeight, timestampToDate, exportCsv, numberWithCommas } = utilily();
  const route = useRoute();
  const state = reactive({
    date: null,
    showDate: null,
    columns: [
      { name: "bill", label: "รหัสบิล", field: "bill", sortable: false },
      {
        name: "customer",
        label: "ชื่อผู้ใช้",
        field: "customer",
        sortable: false,
      },
      { name: "list", label: "รายการหวย", field: "list", sortable: false },
      { name: "round", label: "รอบ", field: "round", sortable: false },
      { name: "amount", label: "จำนวน", field: "amount", sortable: false },
      { name: "result", label: "ยอดได้เสีย", field: "result", sortable: false },
      {
        name: "createtime",
        label: "เวลาที่สร้าง",
        field: "createtime",
        sortable: false,
      },
      {
        name: "resulttime",
        label: "เวลาออกผล",
        field: "resulttime",
        sortable: false,
      },
      {
        name: "status",
        label: "สถานะ",
        field: "status",
        sortable: false,
      },
      {
        name: "other",
        label: "",
        field: "other",
        sortable: false,
        align: "center",
      },
    ],
    loading: false,
    rows: [],
    filters: "",
    pagination: {
      page: 1,
      rowsPerPage: 25,
    },
    pagesNumber: computed(() =>
      Math.ceil(state.total / state.pagination.rowsPerPage)
    ),
    total: 0,
    pagesAlready: [],
    options: ["Google", "Facebook", "Twitter", "Apple", "Oracle"],
    optionsBills: [
      { id: null, desc: "บิลทั้งหมด" },
      { id: "win", desc: "บิลถูกรางวัล" },
      { id: "loss", desc: "บิลเสีย" },
      { id: "dept", desc: "บิลหนี้" },
    ],
    optionsLotsGroup: [],
    optionsStatus: [
      { id: null, desc: "ทุกสถานะ" },
      { id: [600, 650], desc: "เรียบร้อย" },
      { id: [100, 150, 200, 500, 580], desc: "รอออกผล" },
      { id: [700, 750, 780, 800, 890], desc: "ยกเลิก" },
    ],
    optionsLotsType: [],
    billId: "",
    customerName: "",
    selectBill: null,
    selectLotGroup: null,
    selectLotType: null,
    selectStatus: null,
    priceMin: "",
    priceMax: "",
    resultMin: "",
    resultMax: "",
    sortDate: null,
    queryVal: {},
    oldSortName: "",
    sortName: null,
    orderBy: false,
  });
  watch(
    () => state.date,
    (date) => {
      if (typeof date === "object") {
        state.showDate = `${date.from} - ${date.to}`;
        const dateFrom = new Date(date.from);
        const dateTo = new Date(date.to);
        state.sortDate = {
          start: dateFrom.getTime() / 1000,
          end: dateTo.getTime() / 1000,
        };
      } else {
        state.showDate = date;
      }
      console.log(state.selectLotGroup);
    }
  );
  onMounted(async () => {
    await setLotGroup();
    handleRouteQuery();
  });
  const handleRouteQuery = async () => {
    if (Object.keys(route.query).length > 0) {
      state.queryVal.type = route.query.type;
      state.queryVal.start = route.query.start;
      state.queryVal.end = route.query.end;

      let startDate = new Date(parseInt(route.query.start) * 1000);
      let endDate = new Date(parseInt(route.query.end) * 1000);
      state.date = {
        from: `${startDate.toISOString().split("T")[0].replaceAll("-", "/")}/`,
        to: `${endDate.toISOString().split("T")[0].replaceAll("-", "/")}`,
      };
      const mapType = {
        win: "บิลถูกรางวัล",
        loss: "บิลเสีย",
        dept: "บิลหนี้",
      };

      state.selectBill = {
        id: route.query.type,
        desc: mapType[route.query.type],
      };

      await submitBillForm();
      // const startDate = new Date(parseInt(route.query.start));
      // const startDateStr =
      //   startDate.getFullYear() +
      //   "/" +
      //   ("0" + startDate.getMonth()).slice(-2) +
      //   "/" +
      //   ("0" + startDate.getDate()).slice(-2);
      // const endDate = new Date(parseInt(route.query.end));
      // const endDateStr =
      //   endDate.getFullYear() +
      //   "/" +
      //   ("0" + endDate.getMonth()).slice(-2) +
      //   "/" +
      //   ("0" + endDate.getDate()).slice(-2);
      // state.showDate = `${startDateStr} - ${endDateStr}`;

      // state.queryVal.type = route.query.type;
      // const index = state.optionsBills.findIndex((object) => {
      //   return object.id === route.query.type;
      // });
      // state.selectBill = state.optionsBills[index];
      // console.log(state.selectBill);
      // // submitBillForm();
      // console.log(route.query);
    }
  };
  const handleExportBtn = async () => {
    const date = state.sortDate !== null ? { date: state.sortDate } : null;
    if (date === null) {
      return;
    }
    const data = {
      op: "poy.csv",
      params: {
        where: {
          ...date,
        },
      },
    };
    const response = await csv(data);
    exportCsv(response, "รายการบิล");
  };
  const setLotGroup = async () => {
    const response = await lottery_group();
    if (response.status === true) {
      for (const key in response.result) {
        const data = { id: key, desc: response.result[key].name };
        state.optionsLotsGroup.push(data);
      }
    }
  };
  const handleLotGroup = async (value) => {
    if (!value || value.id === "yeekee") {
      state.optionsLotsGroup = [];
      state.selectLotType = null;
      return;
    }
    const data = {
      op: "options.lottery",
      params: {
        group: value.id,
      },
    };
    const response = await lottery_type(data);
    if (response.status === true) {
      response.result.forEach((element) => {
        const data = { id: element._id, desc: element.name };
        state.optionsLotsType.push(data);
      });
    }
  };
  const provideData = () => {
    const billId =
      state.billId !== "" ? { uniqid: parseInt(state.billId) } : null;
    const customerName =
      state.customerName !== "" ? { customer: state.customerName } : null;
    const selectBill =
      state.selectBill !== null && state.selectBill.id !== null
        ? { poy_type: state.selectBill.id }
        : null;
    const selectLotGroup =
      state.selectLotGroup !== null && state.selectLotGroup.id !== null
        ? { "lotterys.group": state.selectLotGroup.id }
        : null;
    const selectLotType =
      state.selectLotType !== null && state.selectLotType.id !== null
        ? { lottery: state.selectLotType.id }
        : null;
    const selectStatus =
      state.selectStatus !== null && state.selectStatus.id !== null
        ? { status: state.selectStatus.id }
        : null;
    const withdraw =
      state.priceMin !== "" && state.priceMax !== ""
        ? {
            withdraw: {
              start: parseInt(state.priceMin),
              end: parseInt(state.priceMax),
            },
          }
        : state.priceMin !== "" && state.priceMax === ""
        ? { withdraw: { start: parseInt(state.priceMin) } }
        : state.priceMin === "" && state.priceMax !== ""
        ? { withdraw: { end: parseInt(state.priceMax) } }
        : null;
    const deposit =
      state.resultMin !== "" && state.resultMax !== ""
        ? {
            deposit: {
              start: parseInt(state.resultMin),
              end: parseInt(state.resultMax),
            },
          }
        : state.resultMin !== "" && state.priceMax === ""
        ? { deposit: { start: parseInt(state.resultMin) } }
        : state.resultMin === "" && state.priceMax !== ""
        ? { deposit: { end: parseInt(state.resultMax) } }
        : null;
    const date = state.sortDate !== null ? { date: state.sortDate } : null;
    const sort = {
      ...billId,
      ...customerName,
      ...selectBill,
      ...selectLotGroup,
      ...selectLotType,
      ...selectStatus,
      ...withdraw,
      ...deposit,
      ...date,
    };
    return sort;
  };
  const setRowsData = (response, offset) => {
    var index = offset !== null ? offset : 0;
    state.total = response.total;
    response.data.forEach((element) => {
      const createDate = timestampToDate(element.created_at.$date);
      const resultDate = timestampToDate(element.rounds.round_final_dt);
      const prepareData = {
        bill: element.uniqid,
        customer: element.customer.username,
        list: element.lotterys.name,
        round: element.rounds.round_name,
        amount: numberWithCommas(element.sum_withdraw_value),
        result:
          element.status === 600 || element.status === 650
            ? numberWithCommas(
                element.sum_deposit_value - element.sum_withdraw_value
              )
            : numberWithCommas(element.sum_deposit_value),
        dept: element.dept_value,
        createtime: createDate,
        resulttime: resultDate,
        status: element.status,
      };
      state.rows[index] = prepareData;
      index++;
    });
  };
  const handleSort = (name) => {
    if (
      name !== "bill" &&
      name !== "amount" &&
      name !== "result" &&
      name !== "createtime" &&
      name !== "status"
    ) {
      return;
    }
    if (state.total <= 0) {
      return;
    }
    var sort = null;
    switch (name) {
      case "bill":
        sort = "uniqid";
        break;
      case "amount":
        sort = "sum_withdraw_value";
        break;
      case "result":
        sort = "sum_deposit_value";
        break;
      case "createtime":
        sort = "created_at ";
        break;
      case "status":
        sort = "status";
        break;
      default:
        break;
    }
    if (state.sortName === sort) {
      if (state.orderBy === true) {
        state.orderBy = false;
      } else {
        state.orderBy = true;
      }
    } else {
      state.orderBy = false;
    }
    state.sortName = sort;
    state.rows = [];
    state.pagesAlready = [];
    submitBillForm((state.pagination.page - 1) * state.pagination.rowsPerPage);
  };
  const handleClickPagination = () => {
    submitBillForm((state.pagination.page - 1) * state.pagination.rowsPerPage);
  };
  const handleClickSubmit = () => {
    state.sortName = null;
    state.orderBy = false;
    console.log("CC");
    submitBillForm(null, true);
  };
  const submitBillForm = async (offset = null, search = false) => {
    if (search === false) {
      if (
        state.pagesAlready.length !== 0 &&
        state.pagesAlready.includes(state.pagination.page) &&
        (state.sortName === null || state.oldSortName === state.sortName)
      ) {
        return;
      }
    }
    console.log("CLICk");
    state.loading = true;
    const sortData = provideData();
    var orderReq = null;
    if (state.sortName !== null) {
      if (state.orderBy === false) {
        orderReq = "asc";
      } else {
        orderReq = "desc";
      }
    }
    var offsetNum = null;
    if (offset !== null) {
      offsetNum = offset !== 0 ? offset : 1;
    }
    const page = state.pagination.page;
    const data = {
      op: "poy.index",
      params: {
        where: {
          ...sortData,
        },
        limit: 25,
        offset: offsetNum === null ? undefined : offsetNum,
        page: page,
        sort: state.sortName === null ? undefined : state.sortName,
        order: orderReq === null ? undefined : orderReq,
      },
    };

    console.log(data);
    const response = await index(data);
    console.log("BILL", response);
    if (response.status === true) {
      state.pagesAlready.push(page);
      state.oldSortName = state.sortName;
      if (offset === null) {
        state.rows = [];
      }
      setRowsData(response.result, offset);
      state.pagination.page = page;
    }
    state.loading = false;
  };
  return {
    state,
    setHeight,
    submitBillForm,
    handleLotGroup,
    handleExportBtn,
    handleClickPagination,
    handleSort,
    handleClickSubmit,
  };
}
export function useDetail() {
  const { setHeight, numberWithCommas, timestampToDate } = utilily();
  const route = useRoute();
  const state = reactive({
    columns: [
      {
        name: "select",
        label: "ตัวเลือกที่เล่น",
        field: "select",
        sortable: true,
      },
      {
        name: "number",
        label: "เลขที่เล่น",
        field: "number",
        sortable: true,
      },
      {
        name: "amount",
        label: "จำนวนที่เล่น",
        field: "amount",
        sortable: true,
      },
      { name: "price", label: "ราคาที่เล่น", field: "price", sortable: true },
      {
        name: "multiply",
        label: "จ่ายต่อบาท",
        field: "multiply",
        sortable: true,
      },
      { name: "result", label: "ยอดได้เสีย", field: "result", sortable: true },
    ],
    rows: [],
    footerColumns: [
      {
        name: "type",
        label: "ประเภท",
        field: "type",
        sortable: false,
      },
      {
        name: "detail",
        label: "รายละเอียด",
        field: "detail",
        sortable: false,
      },
      {
        name: "date",
        label: "วันที่",
        field: "date",
        sortable: false,
      },
    ],
    footerRows: [
      {
        type: "-",
        detail: "-",
        date: "-",
      },
    ],
    pagination: { rowsPerPage: 0 },
    billDetail: null,
    confirmDialog: false,
    successAlert: false,
  });
  onMounted(async () => {
    await prepareBillData();
  });
  const handleClickCancel = async () => {
    const data = {
      op: "poy.void",
      params: {
        uniqid: state.billDetail.uniqid,
      },
    };
    const response = await poyvoid(data);
    if (response.status === true) {
      state.successAlert = true;
      prepareBillData();
    }
    console.log(response);
  };
  const prepareBillData = async () => {
    const data = {
      op: "poy.show",
      params: {
        uniqid: route.params.id,
      },
    };
    const response = await show(data);
    if (response.status === true) {
      state.footerRows[0].type = response.result.status_text;
      state.footerRows[0].detail = response.result.note;
      state.footerRows[0].date = timestampToDate(
        response.result.round.round_final_dt
      );
      response.result.poy_list.forEach((element) => {
        const data = {
          select: element.option_text,
          number: element.number,
          amount: element.number_of_set
            ? numberWithCommas(element.number_of_set)
            : "1",
          price: numberWithCommas(element.price),
          multiply: element.multiply,
          result:
            element.reward <= 0
              ? numberWithCommas(element.reward - element.price)
              : numberWithCommas(element.reward),
        };
        state.rows.push(data);
      });
      state.billDetail = response.result;
      state.billDetail.total_reward =
        state.billDetail.status === 600 || state.billDetail.status === 650
          ? state.billDetail.total_reward - state.billDetail.total_price
          : state.billDetail.total_reward;
    }
    console.log(response);
  };
  return {
    state,
    setHeight,
    handleClickCancel,
    numberWithCommas,
    timestampToDate,
  };
}
