import { reactive } from "vue";
import { changepw } from "@/services/auth-service";

export function useChange() {
  const state = reactive({
    oldPassword: "",
    newPassword: "",
    newPasswordCF: "",
    isPwd1: true,
    isPwd2: true,
    isPwd3: true,
    alert1: false,
    alert2: false,
  });
  const onSubmit = async () => {
    if (state.newPassword !== state.newPasswordCF) {
      state.alert1 = true;
      return;
    }
    console.log(state.oldPassword, state.newPassword, state.newPasswordCF);
    const data = {
      op: "system.changepw",
      params: {
        old_password: state.oldPassword,
        new_password: state.newPasswordCF,
      },
    };
    const response = await changepw(data);
    console.log("changepw", response);
    if (response.status === true) {
      state.alert2 = true;
    }
  };
  return { state, onSubmit };
}
