<template>
  <q-page class="q-pa-md custom-page" :style-fn="setHeight">
    <div class="row q-mb-md" style="height: 5%">
      <div class="flex items-center">
        <span class="title text-h5">หน้ารายละเอียดฝากถอน</span>
        &emsp;
        <q-breadcrumbs>
          <q-breadcrumbs-el label="Admin" />
          <q-breadcrumbs-el label="รายการฝากถอน" />
          <q-breadcrumbs-el label="รายละเอียดฝากถอน" />
        </q-breadcrumbs>
      </div>
      <q-space />
      <div class="flex">
        <span class="title text-h5"
          >รหัสฝากถอน :
          {{
            financialDetail !== null ? financialDetail.source_uniqid : ""
          }}</span
        >
      </div>
    </div>
    <div class="row q-mb-md q-col-gutter-y-md">
      <div class="col-12">
        <q-card class="my-card q-pa-lg" style="background-color: #e9f2ff">
          <q-card-section horizontal>
            <q-card-section class="col-2 text-grey text-subtitle1">
              <span class="">ชื่อผู้ใช้</span>
            </q-card-section>
            <q-card-section class="col-4 text-body1">
              {{
                financialDetail !== null
                  ? financialDetail.customer.username
                  : ""
              }}
            </q-card-section>
            <q-card-section class="col-2 text-grey text-subtitle1">
              <span class="">รายการ</span>
            </q-card-section>
            <q-card-section class="col-4 text-body1">{{
              financialDetail !== null && financialDetail.type === 100
                ? "ฝากเงิน"
                : financialDetail !== null && financialDetail === 200
                ? "ถอนเงิน"
                : "unknown"
            }}</q-card-section>
          </q-card-section>
          <!-- ====== -->
          <q-card-section horizontal>
            <q-card-section class="col-2 text-grey text-subtitle1">
              <span class="">รหัสผู้ใช้</span>
            </q-card-section>
            <q-card-section class="col-4 text-body1">
              {{
                financialDetail !== null ? financialDetail.customer_uniqid : ""
              }}
            </q-card-section>
            <q-card-section class="col-2 text-grey text-subtitle1">
              <span class="">จำนวนเงิน</span>
            </q-card-section>
            <q-card-section class="col-4 text-body1"
              >{{
                financialDetail !== null
                  ? numberWithCommas(financialDetail.amount)
                  : ""
              }}
              บาท</q-card-section
            >
          </q-card-section>
          <!-- ====== -->
          <q-card-section horizontal>
            <q-card-section class="col-2 offset-6 text-grey text-subtitle1">
              <span class="">วันที่ - เวลา</span>
            </q-card-section>
            <q-card-section class="col-4 text-body1">
              {{
                financialDetail !== null ? financialDetail.created_at.$date : ""
              }}
            </q-card-section>
          </q-card-section>
          <!-- ====== -->
          <q-card-section horizontal>
            <q-card-section class="col-2 offset-6 text-grey text-subtitle1">
              <span class="">สถานะ</span>
            </q-card-section>
            <q-card-section class="col-4 text-body1">
              {{
                financialDetail !== null && financialDetail.status === 400
                  ? "เรียบร้อย"
                  : financialDetail !== null && financialDetail.status === 100
                  ? "ยกเลิก"
                  : "Unknown"
              }}
            </q-card-section>
          </q-card-section>
          <!-- ====== -->
        </q-card>
      </div>
    </div>
  </q-page>
</template>

<script>
import { toRefs } from "@vue/reactivity";
import { useDetail } from "./composable/use-financial";

export default {
  setup() {
    const { state, setHeight, numberWithCommas } = useDetail();
    return { ...toRefs(state), setHeight, numberWithCommas };
  },
};
</script>

<style lang="scss" scoped></style>
