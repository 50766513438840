import { h, resolveComponent } from "vue";
import CustomerIndex from "@/views/customer/CustomerIndex.vue";
import CustomerDetail from "@/views/customer/CustomerDetail.vue";

const customerRoutes = [
  {
    path: "",
    name: "customerRoutes",
    component: {
      render: () => h(resolveComponent("router-view")),
    },
    children: [
      {
        path: "",
        name: "CustomerIndex",
        component: CustomerIndex,
      },
      {
        path: ":id",
        name: "CustomerDetail",
        component: CustomerDetail,
      },
    ],
  },
];

export default customerRoutes;
