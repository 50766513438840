<template>
  <q-page class="q-pa-md custom-page" :style-fn="setHeight">
    <div class="row q-mb-md" style="height: 5%">
      <div class="flex items-center">
        <span class="title text-h5">หน้ารายละเอียดผู้ใช้</span>
        &emsp;
        <q-breadcrumbs>
          <q-breadcrumbs-el label="Admin" />
          <q-breadcrumbs-el label="รายการผู้ใช้" />
          <q-breadcrumbs-el label="รายละเอียดผู้ใช้" />
        </q-breadcrumbs>
      </div>
      <q-space />
      <div class="flex">
        <span class="title text-h5"
          >รหัสผู้ใช้ :
          {{ customerDetail !== null ? customerDetail.uniqid : "" }}</span
        >
        &emsp;
        <q-btn
          v-if="customerDetail !== null && customerDetail.status !== 500"
          @click="handleClickUpdate(500)"
          color="amber"
          label="ระงับชั่วคราว"
        />
        <q-btn
          v-else-if="customerDetail !== null && customerDetail.status === 500"
          @click="handleClickUpdate(200)"
          color="green"
          label="ปลดระงับชั่วคราว"
        />
        &emsp;
        <q-btn
          v-if="customerDetail !== null && customerDetail.status !== 700"
          @click="handleClickUpdate(700)"
          color="negative"
          label="แบนผู้ใช้"
        />
        <q-btn
          v-else-if="customerDetail !== null && customerDetail.status === 700"
          @click="handleClickUpdate(200)"
          color="grey"
          label="ปลดแบนผู้ใช้"
        />
      </div>
    </div>
    <div class="row q-mb-md q-col-gutter-y-md">
      <div class="col-12">
        <q-card class="my-card q-pa-lg" style="background-color: #e9f2ff">
          <q-card-section horizontal>
            <q-card-section class="col-2 text-grey text-subtitle1">
              <span class="">ชื่อผู้ใช้</span>
            </q-card-section>
            <q-card-section class="col-4 text-body1">
              {{ customerDetail !== null ? customerDetail.username : "" }}
            </q-card-section>
            <q-card-section class="col-2 text-grey text-subtitle1">
              <span class="">สถานะ</span>
            </q-card-section>
            <q-card-section class="col-4 text-body1">
              {{
                customerDetail !== null && customerDetail.status === 200
                  ? "ปกติ"
                  : customerDetail !== null && customerDetail.status === 500
                  ? "ระงับชั่วคราว"
                  : customerDetail !== null && customerDetail.status === 700
                  ? "แบน"
                  : "unknown"
              }}
            </q-card-section>
          </q-card-section>
          <!-- ====== -->
          <q-card-section horizontal>
            <q-card-section class="col-2 text-grey text-subtitle1">
              <span class="">Username</span>
            </q-card-section>
            <q-card-section class="col-4 text-body1">
              {{ customerDetail !== null ? customerDetail.nickname : "" }}
            </q-card-section>
            <q-card-section class="col-2 text-grey text-subtitle1">
              <span class="">ยอดเงินคงเหลือ</span>
            </q-card-section>
            <q-card-section class="col-4 text-body1">
              {{
                customerDetail !== null
                  ? numberWithCommas(customerDetail.wallet.balance)
                  : ""
              }}฿
            </q-card-section>
          </q-card-section>
          <!-- ====== -->
          <q-card-section horizontal>
            <q-card-section class="col-2 text-grey text-subtitle1">
              <span class="">ระดับผู้ใช้</span>
            </q-card-section>
            <q-card-section class="col-4 text-body1">
              {{ customerDetail !== null ? customerDetail.level_text : "" }}
            </q-card-section>
            <q-card-section class="col-2 text-grey text-subtitle1">
              <span class="">เวลาฝากเงินล่าสุด</span>
            </q-card-section>
            <q-card-section class="col-4 text-body1">
              {{
                customerDetail !== null
                  ? customerDetail.wallet.last_deposit_time
                  : ""
              }}
            </q-card-section>
          </q-card-section>
          <!-- ====== -->
          <q-card-section horizontal>
            <q-card-section class="col-2 offset-6 text-grey text-subtitle1">
              <span class="">เวลาถอนเงินล่าสุด</span>
            </q-card-section>
            <q-card-section class="col-4 text-body1">
              {{
                customerDetail !== null
                  ? customerDetail.wallet.last_withdraw_time
                  : ""
              }}
            </q-card-section>
          </q-card-section>
          <!-- ====== -->
        </q-card>
      </div>
      <div class="col-12 flex justify-end">
        <q-btn color="positive" label="รายการฝากเงิน" />
        &emsp;
        <q-btn color="primary" label="รายการเดิมพัน" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <q-table
          class="footer-table"
          table-class="text-grey-8"
          table-header-class="text-black"
          :table-header-style="{ backgroundColor: '#DDDDDD' }"
          :rows="footerRows"
          :columns="footerColumns"
          row-key="date"
          v-model:pagination="pagination"
          :rows-per-page-options="[0]"
          hide-bottom
        >
        </q-table>
      </div>
    </div>
  </q-page>
</template>

<script>
import { useDetail } from "./composable/use-customer";
import { toRefs } from "@vue/reactivity";

export default {
  setup() {
    const { state, setHeight, handleClickUpdate, numberWithCommas } =
      useDetail();
    return { ...toRefs(state), setHeight, handleClickUpdate, numberWithCommas };
  },
};
</script>

<style lang="scss" scoped></style>
