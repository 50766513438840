import { onMounted, reactive, computed } from "vue";
import { utilily } from "@/utilities/util";
import { index, show } from "@/services/financial-service";
import { useRoute } from "vue-router";

export function useIndex() {
  const { setHeight, timestampToDate, numberWithCommas } = utilily();
  const state = reactive({
    optionsStatus: [
      { id: null, desc: "สถานะทั้งหมด" },
      { id: 400, desc: "เรียบร้อย" },
      { id: 100, desc: "ยกเลิก" },
    ],
    optionsLists: [
      { id: null, desc: "รายการทั้งหมด" },
      { id: 100, desc: "ฝาก" },
      { id: 200, desc: "ถอน" },
    ],
    columns: [
      {
        name: "id",
        label: "รายการฝากถอน",
        field: "id",
        sortable: false,
        align: "center",
      },
      {
        name: "customer",
        label: "ชื่อผู้ใช้",
        field: "customer",
        sortable: false,
      },
      { name: "list", label: "รายการ", field: "list", sortable: false },
      { name: "amount", label: "จำนวนเงิน", field: "amount", sortable: false },
      {
        name: "date",
        label: "วันที่ - เวลา",
        field: "date",
        sortable: false,
      },
      {
        name: "other",
        label: "",
        field: "other",
        sortable: false,
        align: "center",
      },
    ],
    rows: [
      // {
      //   id: "D012311231",
      //   customer: "NateMohammed",
      //   list: "ฝาก",
      //   amount: "500",
      //   date: "01/02/65 12:04",
      //   other: "1",
      // },
    ],
    pagination: {
      page: 1,
      rowsPerPage: 25,
    },
    pagesNumber: computed(() =>
      Math.ceil(state.total / state.pagination.rowsPerPage)
    ),
    total: 0,
    pagesAlready: [],
    loading: false,
    oldSortName: "",
    sortName: null,
    orderBy: false,
    selectStatus: null,
    selectList: null,
    uniqId: "",
    customerId: "",
  });
  const provideData = () => {
    const uniqId =
      state.uniqId !== "" ? { uniqid: parseInt(state.uniqId) } : null;
    const customerId =
      state.customerId !== "" ? { customer: state.customerId } : null;
    const selectList =
      state.selectList !== null && state.selectList.id !== null
        ? { type: state.selectList.id }
        : null;
    const selectStatus =
      state.selectStatus !== null && state.selectStatus.id !== null
        ? { status: state.selectStatus.id }
        : null;
    const sort = {
      ...uniqId,
      ...customerId,
      ...selectStatus,
      ...selectList,
    };
    return sort;
  };
  const setRowsData = (response, offset) => {
    var index = offset !== null ? offset : 0;
    state.total = response.total;
    response.data.forEach((element) => {
      const createDate = timestampToDate(element.created_at.$date);
      const prepareData = {
        id: element.source_uniqid,
        customer: element.customer.username,
        list: element.type,
        amount: numberWithCommas(element.amount),
        date: createDate,
        other: element.uniqid,
      };
      state.rows[index] = prepareData;
      index++;
    });
  };
  const handleSort = (name) => {
    if (name !== "list" && name !== "date") {
      return;
    }
    if (state.total <= 0) {
      return;
    }
    var sort = null;
    switch (name) {
      case "list":
        sort = "type";
        break;
      case "date":
        sort = "created_at.$date";
        break;
      default:
        break;
    }
    if (state.sortName === sort) {
      if (state.orderBy === true) {
        state.orderBy = false;
      } else {
        state.orderBy = true;
      }
    } else {
      state.orderBy = false;
    }
    state.sortName = sort;
    state.rows = [];
    state.pagesAlready = [];
    submitSortFinancial(
      (state.pagination.page - 1) * state.pagination.rowsPerPage
    );
  };
  const handleClickPagination = () => {
    submitSortFinancial(
      (state.pagination.page - 1) * state.pagination.rowsPerPage
    );
  };
  const handleClickSubmit = () => {
    state.sortName = null;
    state.orderBy = false;
    submitSortFinancial();
  };
  const submitSortFinancial = async (offset = null) => {
    if (
      state.pagesAlready.length !== 0 &&
      state.pagesAlready.includes(state.pagination.page) &&
      (state.sortName === null || state.oldSortName === state.sortName)
    ) {
      return;
    }
    state.loading = true;
    const sortData = provideData();
    var orderReq = null;
    if (state.sortName !== null) {
      if (state.orderBy === false) {
        orderReq = "asc";
      } else {
        orderReq = "desc";
      }
    }
    var offsetNum = null;
    if (offset !== null) {
      offsetNum = offset !== 0 ? offset : 1;
    }
    const page = state.pagination.page;
    const data = {
      op: "transaction.index",
      params: {
        where: {
          ...sortData,
        },
        limit: 25,
        offset: offsetNum === null ? undefined : offsetNum,
        page: page,
        sort: state.sortName === null ? undefined : state.sortName,
        order: orderReq === null ? undefined : orderReq,
      },
    };
    console.log(data);
    const response = await index(data);
    if (response.status === true) {
      state.pagesAlready.push(page);
      state.oldSortName = state.sortName;
      if (offset === null) {
        state.rows = [];
      }
      setRowsData(response.result, offset);
      state.pagination.page = page;
    }
    console.log(response);
    state.loading = false;
  };
  return {
    state,
    setHeight,
    submitSortFinancial,
    handleSort,
    handleClickPagination,
    handleClickSubmit,
  };
}

export function useDetail() {
  const { setHeight, timestampToDate, numberWithCommas } = utilily();
  const route = useRoute();
  const state = reactive({
    financialDetail: null,
  });
  onMounted(() => {
    prepareFinancialData();
  });
  const prepareFinancialData = async () => {
    const data = {
      op: "transaction.show",
      params: {
        uniqid: route.params.id,
      },
    };
    const response = await show(data);
    if (response.status === true) {
      const createDate = timestampToDate(response.result.created_at.$date);
      state.financialDetail = response.result;
      state.financialDetail.created_at.$date = createDate;
    }
    console.log(response);
  };
  return { state, setHeight, numberWithCommas };
}
