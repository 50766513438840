<template>
  <q-page class="q-pa-md custom-page" :style-fn="setHeight">
    <div class="row q-mb-md">
      <div class="flex items-center">
        <span class="title text-h5">หน้ารายการเลขอั้น</span>
        &emsp;
        <q-breadcrumbs>
          <q-breadcrumbs-el label="Admin" />
          <q-breadcrumbs-el label="รายการเลขอั้น" />
        </q-breadcrumbs>
      </div>
    </div>
    <div class="row q-mb-md q-col-gutter-y-md q-col-gutter-x-md">
      <div class="col-3">
        <q-select
          @update:model-value="(val) => getPoyData(val)"
          v-model="selectPoyType"
          :options="optionsPoyType"
          option-value="id"
          option-label="desc"
          label="เลือกประเภทหวย"
          filled
          dense
          options-dense
        />
      </div>
      <div class="col-3">
        <q-select
          @update:model-value="(val) => getRoundData(val)"
          v-model="selectPoy"
          :options="optionsPoy"
          option-value="id"
          option-label="desc"
          label="เลือกหวย"
          filled
          dense
          options-dense
        />
      </div>
      <div class="col-3">
        <q-select
          @update:model-value="(val) => getFormatData(val)"
          v-model="selectRound"
          :options="optionsRound"
          option-value="id"
          option-label="desc"
          label="เลือกรอบ"
          filled
          dense
          options-dense
        />
      </div>
      <div class="col-3">
        <q-select
          @update:model-value="(val) => getIndexList(val)"
          v-model="selectFormat"
          :options="optionsFormat"
          option-value="id"
          option-label="desc"
          label="เลือกรูปแบบเลข"
          filled
          dense
          options-dense
        />
      </div>
    </div>
    <q-table
      class="sticky-virtscroll-table financial-table"
      table-class="text-grey-8"
      table-header-class="text-black"
      :table-header-style="{ backgroundColor: '#B0CEFC' }"
      :rows="rows"
      :columns="columns"
      row-key="id"
      virtual-scroll
      v-model:pagination="pagination"
      :rows-per-page-options="[0]"
    >
      <template v-slot:body-cell="props">
        <q-td :props="props" class="vertical-top">
          <span>{{ props.value }}</span>
        </q-td>
      </template>
      <template v-slot:body-cell-range="props">
        <q-td key="range" :props="props" class="vertical-top">
          <span>{{
            props.row.min === null
              ? "ไม่ได้ระบุ"
              : numberWithCommas(props.row.min) +
                " - " +
                numberWithCommas(props.row.max)
          }}</span>
        </q-td>
      </template>
      <template v-slot:body-cell-amount="props">
        <q-td key="amount" :props="props" class="vertical-top">
          <span>{{
            props.value === null ? "ไม่ได้ระบุ" : numberWithCommas(props.value)
          }}</span>
        </q-td>
      </template>
      <template v-slot:body-cell-result="props">
        <q-td key="result" :props="props">
          <q-badge
            class="reward-badge q-py-sm"
            style="background: #9dfd8d"
            v-for="n in props.value"
            :key="n"
            text-color="black"
            :label="
              props.row.reward[n - 1].amt +
              ' => ' +
              props.row.reward[n - 1].reward
            "
          />
        </q-td>
      </template>
      <template v-slot:body-cell-status="props">
        <q-td key="status" :props="props" class="vertical-top">
          <q-badge
            v-if="props.value === true"
            class="q-py-sm"
            color="green"
            text-color="white"
            label="เปิด"
          />
          <q-badge
            v-else
            class="q-py-sm"
            color="red"
            text-color="white"
            label="ปิด"
          />
        </q-td>
      </template>
      <template v-slot:body-cell-other="props">
        <q-td key="other" :props="props" class="vertical-top">
          <!-- <span>{{ props }}</span> -->
          <q-btn
            rounded
            size="sm"
            color="white"
            text-color="black"
            label="แก้ไข"
            @click="handleClickEdit(props.row.uniqid)"
          />
        </q-td>
      </template>
    </q-table>
    <div class="row justify-center q-mt-md">
      <q-btn
        v-if="hideBtn === false"
        @click="rangeBtn = true"
        class="q-mx-md"
        color="primary"
        icon="assignment"
        label="ตรวจสอบอัตราจ่ายแต่ละเลข"
      />
      <q-btn
        v-if="hideBtn === false"
        @click="addBtn = true"
        class="q-mx-md"
        color="secondary"
        icon="add_circle"
        label="เพิ่มเลขใหม่"
      />
      <q-btn
        v-if="hideBtn === false"
        @click="sumBtn = true"
        class="q-mx-md"
        color="grey"
        icon="assignment_turned_in"
        label="สรุปเลขอั้นทั้งหมด"
      />
    </div>
    <RangeDialog
      :openDialog="rangeBtn"
      :poy="selectPoy"
      :round="selectRound"
      :format="selectFormat"
      @closeDialog="rangeBtn = false"
    />
    <SummaryDialog
      :openDialog="sumBtn"
      :poy="selectPoy"
      :round="selectRound"
      :format="selectFormat"
      @closeDialog="sumBtn = false"
    />
    <AddDialog
      :openDialog="addBtn"
      :poy="selectPoy"
      :round="selectRound"
      :format="selectFormat"
      :type="dialogType"
      @closeDialog="handleCloseAddDialog"
      @saveNumber="requestList"
    />
  </q-page>
</template>

<script>
import { toRefs } from "vue";
import { useIndex } from "./composable/use-numbered";
import RangeDialog from "@/components/dialog/RangeDialog.vue";
import SummaryDialog from "@/components/dialog/SummaryDialog.vue";
import AddDialog from "@/components/dialog/AddDialog.vue";

export default {
  components: {
    RangeDialog,
    SummaryDialog,
    AddDialog,
  },
  setup() {
    const {
      state,
      setHeight,
      getPoyData,
      getRoundData,
      getFormatData,
      getIndexList,
      requestList,
      handleClickEdit,
      numberWithCommas,
      handleCloseAddDialog,
    } = useIndex();

    return {
      ...toRefs(state),
      setHeight,
      getPoyData,
      getRoundData,
      getFormatData,
      getIndexList,
      requestList,
      handleClickEdit,
      numberWithCommas,
      handleCloseAddDialog,
    };
  },
};
</script>

<style lang="scss" scoped></style>
