<template>
  <div>
    <highcharts
      class="hc highcharts-figure"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Highcharts from "highcharts";
export default defineComponent({
  name: "BetAmountChart",
  data(instance) {
    return {
      chartOptions: {
        chart: {
          zoomType: "xy",
          marginLeft: 200,
          marginRight: 200,
          spacingTop: 30,
          spacingBottom: 30,
        },
        title: {
          text: undefined,
        },
        xAxis: [
          {
            type: "category",
            labels: {
              rotation: -45,
            },
            crosshair: true,
          },
        ],
        yAxis: [
          {
            // Primary yAxis
            labels: {
              format: "{value:,.0f} ครั้ง",
              style: {
                color: Highcharts.getOptions().colors[1],
              },
            },
            title: {
              text: "จำนวนการเดิมพัน",
              style: {
                color: Highcharts.getOptions().colors[1],
              },
            },
            softMax: 1000,
          },
          {
            // Secondary yAxis
            title: {
              text: "ยอดเดิมพัน",
              style: {
                color: Highcharts.getOptions().colors[0],
              },
            },
            labels: {
              format: "{value:,.0f} บาท",
              style: {
                color: Highcharts.getOptions().colors[0],
              },
            },
            opposite: true,
            softMax: 1000,
          },
        ],
        tooltip: {
          shared: true,
          split: true,
        },
        legend: {
          layout: "vertical",
          align: "left",
          x: 200,
          verticalAlign: "top",
          y: 80,
          floating: true,
          backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || // theme
            "rgba(255,255,255,0.25)",
        },
        series: [
          {
            name: "ยอดเดิมพัน",
            type: "column",
            yAxis: 1,
            data: instance.betAmountData,
            tooltip: {
              valueSuffix: " บาท",
              pointFormat: "ยอดเดิมพัน {point.y:,.0f} บาท",
            },
          },
          {
            name: "จำนวนการเดิมพัน",
            type: "spline",
            data: instance.totalBetFreq,
            tooltip: {
              valueSuffix: "ครั้ง",
              pointFormat: "จำนวนการเดิมพัน {point.y:,.0f} ครั้ง",
            },
          },
        ],
      },
    };
  },
  props: {
    betAmountData: Array,
    totalBetFreq: Array,
  },
  watch: {
    betAmountData: {
      handler: function (val) {
        this.$data.chartOptions.series[0].data = val;
      },
    },
    totalBetFreq: {
      handler: function (val) {
        this.$data.chartOptions.series[1].data = val;
      },
    },
  },
});
</script>
