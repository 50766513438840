<template>
  <q-dialog class="dialog" v-model="open" persistent>
    <q-card style="width: 1000px; max-width: 1000px">
      <q-card-section class="row header">
        <div class="text-h5">
          สรุปเลขอั้น สรุปเลขอั้น {{ poy !== null ? poy.desc : "" }}
          {{ round !== null ? round.desc : "" }} /
          {{ format !== null ? format.desc : "" }}
        </div>
      </q-card-section>
      <q-card-section class="row justify-end">
        <div class="col-3">
          <q-input filled v-model="findNumberText" label="ค้นหาตัวเลข" dense>
            <template v-slot:append>
              <q-icon
                v-if="findNumberText"
                name="cancel"
                @click.stop="handleClickClear"
                class="cursor-pointer"
              />
              <q-icon
                @click="handleClickSearch"
                class="cursor-pointer"
                name="search"
              />
            </template>
          </q-input>
        </div>
      </q-card-section>
      <q-card-section class="row q-pt-none">
        <div class="col-12">
          <q-table
            class="sticky-virtscroll-table table financial-table"
            table-class="text-grey-8"
            table-header-class="text-white"
            :table-header-style="{ backgroundColor: '#929292' }"
            :rows="rows"
            :columns="columns"
            row-key="condition"
            :separator="separator"
            virtual-scroll
            v-model:pagination="pagination"
            :rows-per-page-options="[0]"
          />
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          @click="$emit('closeDialog')"
          label="ปิด"
          color="grey"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { reactive, toRefs, watch } from "vue";
import { summary } from "@/services/numbered-service";

export default {
  props: ["openDialog", "poy", "round", "format"],
  setup(props) {
    const state = reactive({
      columns: [
        {
          name: "numberGroup",
          label: "กลุ่มตัวเลข",
          field: "numberGroup",
          sortable: true,
          align: "center",
        },
        {
          name: "number",
          label: "เลข",
          field: "number",
          sortable: true,
          align: "center",
        },
        {
          name: "maxPerTime",
          label: "สูงสุดต่อครั้ง",
          field: "maxPerTime",
          sortable: true,
          align: "center",
        },
        {
          name: "maxPerPerson",
          label: "มากสุดต่อคน",
          field: "maxPerPerson",
          sortable: true,
          align: "center",
        },
        {
          name: "perBaht",
          label: "บาทละ",
          field: "perBaht",
          sortable: true,
          align: "center",
        },
        {
          name: "maxReceive",
          label: "รับสูงสุด",
          field: "maxReceive",
          sortable: true,
          align: "center",
        },
      ],
      rows: [
        // {
        //   numberGroup: "879",
        //   number: "879",
        //   maxPerTime: "500",
        //   maxPerPerson: "700",
        //   perBaht: "900",
        //   maxReceive: "10000",
        // },
      ],
      backupRows: [],
      pagination: { rowsPerPage: 10 },
      separator: "vertical",
      open: false,
      findNumberText: "",
      poy: [],
      round: [],
      format: [],
    });
    watch(
      () => [props.openDialog, props.poy, props.round, props.format],
      (
        [openDialog, poy, round, format],
        [prevOpenDialog, prevPoy, prevRound, prevFormat]
      ) => {
        console.log(prevOpenDialog);
        if (state.open !== openDialog) {
          state.open = openDialog;
          if (state.open === true) {
            state.backupRows = [];
            state.rows = [];
            handleOpenDialog();
          }
        }
        if (prevPoy === null || prevPoy.length !== 0) {
          state.poy = poy;
        }
        if (prevRound === null || prevRound.length !== 0) {
          state.round = round;
        }
        if (prevFormat === null || prevFormat.length !== 0) {
          state.format = format;
        }
      }
    );
    const handleOpenDialog = async () => {
      const data = {
        op: "betconfig.getNumsFinalBetConfig",
        params: {
          round_oid: state.round.id,
          bet_option_id: state.format.id,
        },
      };
      const response = await summary(data);
      if (response.status === true) {
        for (const key in response.result) {
          const tableData = {
            numberGroup: response.result[key].number_group,
            number: key,
            maxPerTime: response.result[key].bet_max,
            maxPerPerson: response.result[key].max_per_number,
            perBaht: response.result[key].reward_step[0].reward,
            maxReceive: response.result[key].reward_step[1].amt,
          };
          state.backupRows.push(tableData);
        }
        state.rows = state.backupRows;
      }
      console.log(response);
    };
    const handleClickSearch = () => {
      const index = state.backupRows.findIndex(
        (element) => element.number === state.findNumberText
      );
      console.log(index);
      if (index !== -1) {
        state.rows = [];
        state.rows.push(state.backupRows[index]);
      }
    };
    const handleClickClear = () => {
      state.findNumberText = "";
      state.rows = state.backupRows;
    };
    return {
      ...toRefs(state),
      handleClickSearch,
      handleClickClear,
    };
  },
};
</script>

<style lang="scss" scoped></style>
