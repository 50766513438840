<template>
  <q-page class="q-pa-md custom-page" :style-fn="setHeight">
    <div class="row q-mb-md">
      <div class="flex items-center">
        <span class="title text-h5">หน้ารายการบิล</span>
        &emsp;
        <q-breadcrumbs>
          <q-breadcrumbs-el label="Admin" />
          <q-breadcrumbs-el label="รายการบิล" />
        </q-breadcrumbs>
      </div>
      <q-space />
      <div class="flex">
        <q-input v-model="showDate" label="วันที่" filled dense>
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy>
                <q-date v-model="date" range />
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        &emsp;
        <q-btn
          @click="handleExportBtn"
          style="background: #f2f2f2"
          text-color="black"
          icon-right="download"
          label="ส่งออก"
          unelevated
        />
      </div>
    </div>
    <div class="row items-center q-mb-md q-col-gutter-y-md q-col-gutter-x-md">
      <div class="col-2">
        <q-input v-model="billId" label="ระบุรหัสบิล" outlined dense />
      </div>
      <div class="col-2">
        <q-input v-model="customerName" label="ระบุชื่อผู้ใช้" outlined dense />
      </div>
      <div class="col-2">
        <q-select
          v-model="selectBill"
          :options="optionsBills"
          option-value="id"
          option-label="desc"
          label="เลือกประเภทบิล"
          :disable="queryVal.type !== undefined ? true : false"
          filled
          dense
          options-dense
        />
      </div>
      <div class="col-2">
        <q-select
          @update:model-value="(val) => handleLotGroup(val)"
          v-model="selectLotGroup"
          :options="optionsLotsGroup"
          option-value="id"
          option-label="desc"
          label="เลือกประเภทหวย"
          filled
          dense
          options-dense
        />
      </div>
      <div class="col-2">
        <q-select
          v-model="selectLotType"
          :options="optionsLotsType"
          option-value="id"
          option-label="desc"
          label="เลือกหวย"
          filled
          dense
          options-dense
        />
      </div>
      <div class="col-2">
        <q-select
          v-model="selectStatus"
          :options="optionsStatus"
          option-value="id"
          option-label="desc"
          label="เลือกสถานะ"
          filled
          dense
          options-dense
        />
      </div>
      <div class="col-4 flex items-center">
        <div class="q-pr-sm">ระบุยอดเล่น</div>
        <div class="col q-pr-xs">
          <q-input v-model="priceMin" placeholder="0" outlined dense />
        </div>
        <div>-</div>
        <div class="col q-pl-xs">
          <q-input v-model="priceMax" placeholder="0" outlined dense />
        </div>
      </div>
      <div class="col-4 flex items-center">
        <div class="q-pr-sm">ระบุยอดได้เสีย</div>
        <div class="col q-pr-xs">
          <q-input v-model="resultMin" placeholder="0" outlined dense />
        </div>
        <div>-</div>
        <div class="col q-pl-xs">
          <q-input v-model="resultMax" placeholder="0" outlined dense />
        </div>
      </div>
      <div class="col-2 offset-2 flex justify-end">
        <q-btn
          class="q-mr-sm"
          color="grey-4"
          text-color="black"
          label="ยกเลิก"
        />
        <q-btn @click="handleClickSubmit()" color="primary" label="ค้นหา" />
      </div>
    </div>
    <q-table
      class="sticky-virtscroll-table bill-table"
      table-class="text-grey-8"
      table-header-class="text-black"
      :table-header-style="{ backgroundColor: '#B0CEFC' }"
      :rows="rows"
      :columns="columns"
      row-key="bill"
      virtual-scroll
      v-model:pagination="pagination"
      :rows-per-page-options="[0]"
      hide-pagination
      :loading="loading"
    >
      <template v-slot:header-cell="props">
        <q-th
          @click="handleSort(props.col.name)"
          :class="[
            props.col.name === 'bill' ||
            props.col.name === 'amount' ||
            props.col.name === 'result' ||
            props.col.name === 'createtime' ||
            props.col.name === 'status'
              ? 'cursor-pointer'
              : '',
          ]"
          :props="props"
        >
          {{ props.col.label }}
        </q-th>
      </template>
      <template v-slot:loading>
        <q-inner-loading class="q-mt-xl" showing color="primary" />
      </template>
      <template v-slot:body-cell-status="props">
        <q-td key="status" :props="props">
          <!-- {{ props.row.dept }} -->
          <q-icon
            v-if="props.value < 600"
            name="watch_later"
            color="warning"
            size="21px"
          />
          <q-icon
            v-else-if="props.value < 700"
            name="check_box"
            color="positive"
            size="21px"
          />

          <q-icon v-else name="disabled_by_default" color="red" size="21px" />
          <q-icon
            v-if="props.row.dept > 0"
            name="assignment_late"
            color="negative"
            size="21px"
          />
        </q-td>
      </template>
      <template v-slot:body-cell-other="props">
        <q-td key="other" :props="props">
          <router-link :to="{ name: 'BillDetail', params: { id: props.key } }">
            <q-icon name="visibility" size="21px" />
          </router-link>
        </q-td>
      </template>
    </q-table>
    <div class="row justify-end q-mt-md">
      <q-pagination
        @click="handleClickPagination"
        v-model="pagination.page"
        color="grey-8"
        :max="pagesNumber"
        :max-pages="6"
        boundary-numbers
        size="md"
      />
    </div>
    <div class="row q-col-gutter-x-md items-center note q-mt-md q-pa-md">
      <div>
        <q-icon name="check_box" color="positive" size="21px" />: เรียบร้อย
      </div>
      <div>
        <q-icon name="watch_later" color="warning" size="21px" />: รอออกผล
      </div>
      <div>
        <q-icon name="disabled_by_default" color="red" size="21px" />: ยกเลิก
      </div>
      <div>
        <q-icon name="assignment_late" color="negative" size="21px" />:
        บิลมีหนี้
      </div>
    </div>
  </q-page>
</template>

<script>
import { toRefs } from "vue";
import { useIndex } from "./composable/use-bill";

export default {
  name: "BillIndex",
  setup() {
    const {
      state,
      setHeight,
      handleClickPagination,
      handleSort,
      submitBillForm,
      handleLotGroup,
      handleExportBtn,
      handleClickSubmit,
    } = useIndex();

    return {
      ...toRefs(state),
      setHeight,
      handleClickPagination,
      handleSort,
      submitBillForm,
      handleLotGroup,
      handleExportBtn,
      handleClickSubmit,
    };
  },
};
</script>

<style lang="scss" scoped></style>
