<template>
  <q-layout
    view="hhh lpr lff"
    style="width: 1600px; margin-left: auto; margin-right: auto"
  >
    <q-header bordered class="header">
      <q-toolbar>
        <q-img src="@/assets/images/logopplotto.png" width="240px" />

        <q-space />
        <q-badge
          color="white"
          text-color="black"
          align="middle"
          class="q-pa-sm"
        >
          <q-icon name="account_circle" :size="'xs'" />&nbsp;
          {{ customer.code }} /{{ customer.web }} </q-badge
        >&emsp;
        <q-btn @click="handleClickLogout" dense flat icon="logout" />
      </q-toolbar>
    </q-header>

    <q-drawer
      show-if-above
      bordered
      behavior="desktop"
      class="sidebar"
      :width="240"
    >
      <q-list>
        <q-item-label header class="column items-center">
          <q-icon name="account_circle" :size="'xl'" :color="'black'" />
          <div>{{ customer.code }}</div>
        </q-item-label>
        <q-item
          v-for="(menuList, index) in menuLists"
          :key="index"
          clickable
          :to="{ name: menuList.redirect }"
          :style="`background-color: ${menuList.bg};color: #686868;`"
        >
          <q-item-section avatar>
            <q-icon :name="menuList.icon" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ menuList.name }}</q-item-label>
            <!-- <q-item-label caption>quasar.dev</q-item-label> -->
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <router-view></router-view>
    </q-page-container>
  </q-layout>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { login } from "@/services/auth-service";
import { useRouter } from "vue-router";
export default {
  name: "LayoutDefault",
  setup() {
    const router = useRouter();
    const state = reactive({
      menuLists: [
        {
          name: "ข้อมูลเชิงลึก",
          icon: "analytics",
          redirect: "InsightIndex",
          bg: "#D3E5FF",
          color: "#686868",
        },
        {
          name: "รายการเลขอั้น",
          icon: "list",
          redirect: "NumberedIndex",
          bg: "#B0CEFC",
          color: "#686868",
        },
        {
          name: "รายการบิล",
          icon: "assignment",
          redirect: "BillIndex",
          bg: "#D3E5FF",
          color: "#686868",
        },
        {
          name: "รายการผู้ใช้",
          icon: "account_circle",
          redirect: "CustomerIndex",
          bg: "#B0CEFC",
          color: "#686868",
        },
        {
          name: "รายการฝากถอน",
          icon: "account_balance",
          redirect: "FinancialIndex",
          bg: "#D3E5FF",
          color: "#686868",
        },
        {
          name: "เปลี่ยนรหัสผ่าน",
          icon: "lock",
          redirect: "ChangePasswordIndex",
          bg: "#B0CEFC",
          color: "#686868",
        },
      ],
      customer: {
        code: "",
        web: "",
      },
    });
    onMounted(() => {
      state.customer.code = localStorage.getItem("code");
      state.customer.web = localStorage.getItem("web");
    });
    const handleClickLogout = async () => {
      const data = { op: "system.signout" };
      const response = await login(data);
      if (response.status === true) {
        console.log(response);
        localStorage.removeItem("code");
        router.replace({ name: "Login" });
      }
    };
    return {
      ...toRefs(state),
      handleClickLogout,
    };
  },
};
</script>
