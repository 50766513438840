import { request } from "./api";

export function poyType(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}

export function poy(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}

export function round(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}

export function format(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}

export function indexList(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}

export function add(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}

export function summary(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}

export function range(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}

export function betConfig(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}

export function configData(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}
