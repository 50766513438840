import axios from "axios";
import router from "@/router/index";
import { utilily } from "@/utilities/util.js";

const BASE_API_URL = process.env.VUE_APP_API_URL
  ? process.env.VUE_APP_API_URL
  : "https://lotto-web-api.softbot.space";
const http = axios.create({
  baseURL: BASE_API_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
const { swalAlert } = utilily();
var swalOption = {
  buttonsStyling: true,
  showCancelButton: true,
  showConfirmButton: false,
  html: "",
  cancelButtonText: "ปิด",
  cancelButtonColor: "#fff",
  background: "#ff7070",
};

export async function request(method, url, data, csv = false) {
  var response;
  let headers = {};
  // if (token) {
  //   const header_auth = {
  //     Authorization: "Bearer " + token,
  //   };

  //   headers = {
  //     ...header_auth,
  //   };
  // }
  try {
    response = await http({
      method,
      url,
      data,
      headers,
    });
  } catch (error) {
    console.log("error ->>", error.response);
  }
  if (response.data.type === "exception") {
    swalOption.html = `<span style='color:#fff'>${response.data.exception.subject}<br/><br/>${response.data.exception.description}<span>`;
    swalAlert.fire(swalOption).then(async (isConfirm) => {
      if (isConfirm.isDismissed) {
        if (response.data.exception.type === 5000) {
          if (response.data.exception.code === 2190) {
            router.replace({ name: "Login" });
          }
        }
      }
    });

    console.log(response.data);
    const data = { status: false };
    return data;
  }
  if (csv === false) {
    response.data.status = true;
  }
  return response.data;
}
