<template>
  <q-dialog class="dialog" v-model="open" persistent>
    <q-card style="width: 1000px; max-width: 1000px">
      <q-card-section class="row header">
        <div class="text-h5">
          สรุปเลขอั้น {{ poy !== null ? poy.desc : "" }}
          {{ round !== null ? round.desc : "" }} /
          {{ format !== null ? format.desc : "" }}
        </div>
        <q-space />
        <div>
          <q-toggle color="green" v-model="status" />
        </div>
      </q-card-section>
      <q-form @submit="handleClickAdd" class="full-width">
        <q-card-section class="q-pb-none">
          <div class="row items-start q-col-gutter-x-md">
            <div class="col-1 text-center">
              <span>เลข</span>
            </div>
            <div class="col-2">
              <q-input
                filled
                v-model="numberText"
                dense
                :rules="[(val) => !!val || 'กรุณากรอกข้อมูล']"
              />
            </div>
            <div class="col-2">
              <q-select
                v-model="selectNumber"
                :options="optionsNumber"
                option-value="id"
                option-label="desc"
                label="เลือกรูปแบบเลข"
                filled
                dense
                options-dense
                :rules="[rule]"
              />
            </div>
            <div class="col-1">
              <q-btn type="submit" label="เพิ่ม" color="positive" />
            </div>
          </div>
        </q-card-section>
      </q-form>
      <q-form
        @submit="handleClickSave(uniqid !== '' ? true : false)"
        class="full-width"
      >
        <q-card-section>
          <div class="row q-col-gutter-x-md">
            <div class="col-6">
              <q-card class="my-card" style="height: 100%">
                <q-card-section>
                  <q-chip
                    v-for="(chip, index) in chips"
                    :key="index"
                    removable
                    v-model="chip.state"
                    color="grey"
                    text-color="white"
                  >
                    {{ chip.value }}
                  </q-chip>
                </q-card-section>
              </q-card>
            </div>
            <div class="col-6 q-col-gutter-y-md">
              <div class="row items-center q-col-gutter-x-md">
                <div class="col-5">
                  <q-checkbox
                    v-model="maxBetPerPerson"
                    label="แทงสูงสุดต่อคน"
                  />
                </div>
                <div class="col-6">
                  <q-input
                    filled
                    v-model="maxBetPerPersonInput"
                    dense
                    :disable="maxBetPerPerson === false"
                    :rules="[(val) => !!val || 'กรุณากรอกข้อมูล']"
                  />
                </div>
                <div class="col-1">
                  <span>บาท</span>
                </div>
              </div>
              <div class="row items-center q-col-gutter-x-md">
                <div class="col-5">
                  <q-checkbox
                    v-model="minBetPerTime"
                    label="แทงต่ำสุดต่อครั้ง"
                  />
                </div>
                <div class="col-6">
                  <q-input
                    filled
                    v-model="minBetPerTimeInput"
                    dense
                    :disable="minBetPerTime === false"
                    :rules="[(val) => !!val || 'กรุณากรอกข้อมูล']"
                  />
                </div>
                <div class="col-1">
                  <span>บาท</span>
                </div>
              </div>
              <div class="row items-center q-col-gutter-x-md">
                <div class="col-5">
                  <q-checkbox
                    v-model="maxBetPerTime"
                    label="แทงสูงสุดต่อครั้ง"
                  />
                </div>
                <div class="col-6">
                  <q-input
                    filled
                    v-model="maxBetPerTimeInput"
                    dense
                    :disable="maxBetPerTime === false"
                    :rules="[(val) => !!val || 'กรุณากรอกข้อมูล']"
                  />
                </div>
                <div class="col-1">
                  <span>บาท</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row q-col-gutter-x-md q-mt-md">
            <div class="col-5 offset-1">
              <div class="row items-center q-col-gutter-x-md">
                <div class="col-5 text-center">
                  <span>รับแทงสูงสุด</span>
                </div>
                <div class="col-6">
                  <q-input
                    filled
                    v-model="limitBetInput"
                    dense
                    :rules="[(val) => !!val || 'กรุณากรอกข้อมูล']"
                  />
                </div>
                <div class="col-1">
                  <span>บาท</span>
                </div>
              </div>
            </div>
            <div class="col-5">
              <div class="row items-center q-col-gutter-x-md">
                <div class="col-5 text-center">
                  <span>จ่ายบาทละ</span>
                </div>
                <div class="col-6">
                  <q-input
                    filled
                    v-model="payPerBahtInput"
                    dense
                    :rules="[(val) => !!val || 'กรุณากรอกข้อมูล']"
                  />
                </div>
                <div class="col-1">
                  <span>บาท</span>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            @click="$emit('closeDialog')"
            label="ยกเลิก"
            color="grey"
            v-close-popup
          />
          <q-btn type="submit" label="บันทึก" color="positive" icon="save" />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
  <q-dialog
    v-model="successAlert"
    persistent
    transition-show="scale"
    transition-hide="scale"
    class="dialog"
  >
    <q-card class="alert bg-light-green-14 text-white">
      <q-card-section class="row items-center justify-center q-px-lg">
        <span class="q-ml-sm">
          {{ dialogType === "" ? "เพิ่มเลขอั้นสำเร็จ" : "แก้ไขเลขอั้นสำเร็จ" }}
        </span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          @click="handleClickCloseDialog"
          label="ปิด"
          color="white"
          text-color="black"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { reactive, toRefs, watch } from "vue";
import { add, betConfig, configData } from "@/services/numbered-service";

export default {
  props: ["openDialog", "poy", "round", "format", "type"],
  emits: ["saveNumber", "closeDialog"],
  setup(props, context) {
    const state = reactive({
      open: false,
      selectNumber: null,
      optionsNumber: [
        { id: 0, desc: "ตัวเดียว" },
        { id: 1, desc: "6 กลับ" },
      ],
      maxBetPerPerson: false,
      minBetPerTime: false,
      maxBetPerTime: false,
      maxBetPerPersonInput: "",
      minBetPerTimeInput: "",
      maxBetPerTimeInput: "",
      limitBetInput: "",
      payPerBahtInput: "",
      status: true,
      uniqid: "",
      numberText: "",
      numberResult: [],
      chips: [],
      poy: null,
      round: null,
      format: null,
      dialogType: "",
      numberArray: [],
      successAlert: false,
    });
    watch(
      () => [
        props.openDialog,
        props.poy,
        props.round,
        props.format,
        props.type,
      ],
      async (
        [openDialog, poy, round, format, type],
        [prevOpenDialog, prevPoy, prevRound, prevFormat, prevType]
      ) => {
        console.log("dialog", open, prevOpenDialog);
        if (state.open !== openDialog) {
          console.log(type, prevType);
          if (openDialog === true) {
            await handleOpenDialog();
            await prepareData(type);
            state.open = openDialog;
          }
        }
        if (prevPoy === null) {
          state.poy = poy;
        }
        if (prevRound === null) {
          state.round = round;
        }
        if (prevFormat === null) {
          state.format = format;
        }
      }
    );
    const handleOpenDialog = async () => {
      const data = {
        op: "betconfig.getFinalBetConfig",
        params: {
          round_oid: state.round.id,
          bet_option_id: state.format.id,
        },
      };
      const response = await betConfig(data);
      if (response.status === true) {
        state.maxBetPerPersonInput = response.result.totalPerCust;
        state.minBetPerTimeInput = response.result.bet_limit.minBet;
        state.maxBetPerTimeInput = response.result.bet_limit.maxBet;
      }
    };
    const prepareData = async (type) => {
      state.chips = [];
      state.numberArray = [];
      state.status = true;
      state.maxBetPerPerson = false;
      state.minBetPerTime = false;
      state.maxBetPerTime = false;
      state.limitBetInput = "";
      state.payPerBahtInput = "";
      state.uniqid = "";
      state.dialogType = type;
      if (state.dialogType !== "") {
        console.log(type);
        const data = {
          op: "betconfig.getBetConfigData",
          params: {
            round_oid: state.round.id,
            bet_option_id: state.format.id,
            uniqid: state.dialogType,
          },
        };
        const response = await configData(data);
        if (response.status === true) {
          state.uniqid = state.dialogType;
          state.maxBetPerPerson =
            response.result.totalPerCust !== null ? true : false;
          state.minBetPerTime =
            response.result.bet_limit.minBet !== null ? true : false;
          state.maxBetPerTime =
            response.result.bet_limit.maxBet !== null ? true : false;
          state.maxBetPerPersonInput =
            response.result.totalPerCust !== null
              ? response.result.totalPerCust
              : state.maxBetPerPersonInput;
          state.minBetPerTimeInput =
            response.result.bet_limit.minBet !== null
              ? response.result.bet_limit.minBet
              : state.minBetPerTimeInput;
          state.maxBetPerTimeInput =
            response.result.bet_limit.maxBet !== null
              ? response.result.bet_limit.maxBet
              : state.maxBetPerTimeInput;
          state.limitBetInput = response.result.reward_limit.maxTotalBet;
          state.payPerBahtInput = response.result.reward_limit.reward;
          state.status = response.result.status;
          response.result.numbers.forEach((element) => {
            if (element !== "") {
              state.chips.push({ state: true, value: element });
              state.numberArray.push(element);
            }
          });
        }
      }
    };
    const numberPermutations = (arr, memo) => {
      var cur,
        memory = memo || [];
      for (let i = 0; i < arr.length; i++) {
        cur = arr.splice(i, 1);
        if (arr.length === 0) {
          state.numberResult.push(memory.concat(cur));
        }
        numberPermutations(arr.slice(), memory.concat(cur));
        arr.splice(i, 0, cur[0]);
      }
    };
    const handleClickAdd = () => {
      if (state.selectNumber.id === 0) {
        let arrMatch = state.chips.find(
          (arr) => arr.value === state.numberText
        );
        if (!arrMatch) {
          state.chips.push({ state: true, value: state.numberText });
          state.numberArray.push(state.numberText);
        }
      } else {
        const splitNumber = state.numberText.split("");
        numberPermutations(splitNumber);
        // console.log(state.numberResult);
        for (let index = 0; index < state.numberResult.length; index++) {
          let stringNumber = state.numberResult[index].join("");
          let arrMatch = state.chips.find((arr) => arr.value === stringNumber);
          if (!arrMatch) {
            state.chips.push({ state: true, value: stringNumber });
            state.numberArray.push(stringNumber);
          }
        }
        state.numberResult = [];
      }
    };
    const handleClickSave = async (edit = false) => {
      var reqState = null;
      if (edit !== false) {
        reqState = { betconfig_uniqid: state.uniqid };
      }
      const data = {
        op:
          edit === false
            ? "betconfig.addNewBetConfig"
            : "betconfig.updateBetConfig",
        params: {
          ...reqState,
          round_oid: state.round.id,
          bet_option_id: state.format.id,
          status: state.status,
          numbers: state.numberArray,
          totalPerCust:
            state.maxBetPerPerson === true
              ? parseInt(state.maxBetPerPersonInput)
              : null,
          bet_limit: {
            minBet:
              state.minBetPerTime === true
                ? parseInt(state.minBetPerTimeInput)
                : null,
            maxBet:
              state.maxBetPerTime === true
                ? parseInt(state.maxBetPerTimeInput)
                : null,
          },
          reward_limit: {
            maxTotalBet: parseInt(state.limitBetInput),
            reward: parseInt(state.payPerBahtInput),
          },
        },
      };
      console.log(data);
      const response = await add(data);
      if (response.status === true) {
        state.successAlert = true;
      }
      console.log(response);
    };
    const handleClickCloseDialog = () => {
      console.log("CLOSE");
      context.emit("saveNumber");
      context.emit("closeDialog");
      state.open = false;
    };
    const rule = (val) => {
      if (val === null) {
        return "กรุณาเลือกรูปแบบตัวเลข";
      }
    };
    return {
      ...toRefs(state),
      handleClickAdd,
      handleClickSave,
      rule,
      handleClickCloseDialog,
    };
  },
};
</script>

<style lang="scss" scoped></style>
