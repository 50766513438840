<template>
  <q-page class="q-pa-md custom-page">
    <div>
      <q-form @submit="onSubmit" class="q-gutter-md" style="width: 25%">
        <q-input
          filled
          v-model="oldPassword"
          :type="isPwd1 ? 'password' : 'text'"
          label="รหัสผ่านเดิม"
          lazy-rules
          :rules="[(val) => !!val || 'กรุณากรอกรหัสผ่าน']"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd1 ? 'visibility' : 'visibility_off'"
              class="cursor-pointer"
              @click="isPwd1 = !isPwd1"
            />
          </template>
        </q-input>
        <q-input
          filled
          v-model="newPassword"
          :type="isPwd2 ? 'password' : 'text'"
          label="รหัสผ่านใหม่"
          lazy-rules
          :rules="[(val) => !!val || 'กรุณากรอกรหัสผ่าน']"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd2 ? 'visibility' : 'visibility_off'"
              class="cursor-pointer"
              @click="isPwd2 = !isPwd2"
            />
          </template>
        </q-input>
        <q-input
          filled
          v-model="newPasswordCF"
          :type="isPwd3 ? 'password' : 'text'"
          label="ยืนยันรหัสผ่านใหม่"
          lazy-rules
          :rules="[(val) => !!val || 'กรุณากรอกรหัสผ่าน']"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd3 ? 'visibility' : 'visibility_off'"
              class="cursor-pointer"
              @click="isPwd3 = !isPwd3"
            />
          </template>
        </q-input>
        <div>
          <q-btn
            class="full-width"
            label="ยืนยัน"
            type="submit"
            color="green"
          />
        </div>
      </q-form>
    </div>
    <q-dialog v-model="alert1" persistent>
      <q-card class="dialog bg-red text-white" style="width: 500px">
        <q-card-section>
          <div class="text-h6">เปลี่ยนรหัสผ่านผิดพลาด</div>
        </q-card-section>

        <q-card-section class="q-pt-none text-body1">
          รหัสผ่านเก่าไม่ถูกต้องหรือรหัสผ่านใหม่ไม่ตรงกัน
        </q-card-section>

        <q-card-actions align="right">
          <q-btn label="ปิด" color="white" text-color="black" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="alert2" persistent>
      <q-card class="dialog bg-green text-white" style="width: 500px">
        <q-card-section class="row justify-center">
          <div class="text-h6">เปลี่ยนรหัสผ่านเรียบร้อย</div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn label="ปิด" color="white" text-color="black" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import { toRefs } from "vue";
import { useChange } from "./composable/use-changepassword";

export default {
  name: "ChangePasswordIndex",
  setup() {
    const { state, onSubmit } = useChange();

    return {
      ...toRefs(state),
      onSubmit,
    };
  },
};
</script>

<style lang="scss" scoped></style>
