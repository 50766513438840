import { request } from "./api";

export function login(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}

export function logout(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}

export function changepw(data) {
  const url = `/webapi/agent`;
  return request("post", url, data);
}
